import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiUrl_order, internal_API ,apiUrl_filter, apiUrl_orders ,apiUrl_orderInfo} from "../../constants/defaultValues";

import {
    ORDERS_GET_LIST,
    ALPHA_GET,
    ALPHA_UPDATE,
    ANDROID_GET,
    ORDER_GET_DETAILS,
    ORDER_DELETE,
    ORDERS_GET_LIST_WITH_FILTER,
    ORDER_UPDATE
} from "../actions";

import {
    getOrdersListSuccess,
    getOrdersListError,
    getAlphaSuccess,
    getAlphaError,
    updateAlphaSuccess,
    updateAlphaError,
    getAndroidSuccess,
    getAndroidError,
    getOrderDetailSuccess,
    getOrderDetailError,
    deleteOrderSuccess,
    getOrdersListWithFilterSuccess,
    getOrdersListWithFilterError,
    updateOrderSuccess,
    updateOrderError
  } from "./actions";
  import axios from 'axios';


const ordersListRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_orders}`,{
          headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
          }
      })
      .then(response => response)
      .catch(error => error);
};
  
function* getOrdersList() {
  try {
      const response = yield call(ordersListRequest);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(getOrdersListSuccess(response.data));
      }else{
        yield put(getOrdersListError(response));
      }
  } catch (error) {
        yield put(getOrdersListError(error));
  }
}

const alphaOrder = async () => {
  return await axios.get(`${internal_API+'api/betaUsers'}`,{
          headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
          }
      })
      .then(response => response)
      .catch(error => error);
};
  
function* getAlpha() {
  try {
      const response = yield call(alphaOrder);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(getAlphaSuccess(response.data));
      }else{
        yield put(getAlphaError(response));
      }
  } catch (error) {
        yield put(getAlphaError(error));
  }
}

const alphaOrderV2 = async itemObj => {
  var body = {};
  if(itemObj.status){
    body['status'] = itemObj.status
  }
  if(itemObj.isDeleted){
    body['isDeleted'] = itemObj.isDeleted
  }
	return await axios.put(`${internal_API+'api/betaUsers/'+itemObj.id}`,body,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}
  
function* updateAlpha({payload}) {
  try {
      const response = yield call(alphaOrderV2, payload);
      if(response && (response.status === 200 || response.status === 201)){
        yield call(getAlpha);
      }else{
        yield put(updateAlphaError(response));
      }
  } catch (error) {
        yield put(updateAlphaError(error));
  }
}

const androidOrder = async () => {
  return await axios.get(`${internal_API+'api/androidUsers'}`,{
          headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
          }
      })
      .then(response => response)
      .catch(error => error);
};
  
function* getAndroid() {
  try {
      const response = yield call(androidOrder);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(getAndroidSuccess(response.data));
      }else{
        yield put(getAndroidError(response));
      }
  } catch (error) {
        yield put(getAndroidError(error));
  }
}

const getOrderDetailRequest = async item => {
  let itemId = item.payload;
	return await axios.get(`${internal_API+apiUrl_orderInfo}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* getOrderDetail(payload) {
	try {
    const response = yield call(getOrderDetailRequest, payload);
		if(response && (response.status === 200 || response.status == 201)){
			yield put(getOrderDetailSuccess(response.data));
		}else{
			yield put(getOrderDetailError(response));
		}
	} catch (error) {
		yield put(getOrderDetailError(error));
	}
}


const deleteOrderRequest = async (itemId, item) => {
	return await axios.delete(`${internal_API+apiUrl_order}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* deleteOrder({ payload }) {
	try {
    const { itemId, item } = payload;
		const response = yield call(deleteOrderRequest, itemId, item);
		if(response && response.status === 200 || response.status === 201){
			yield put(deleteOrderSuccess(response));
		  }else{
			yield put(getOrderDetailError(response));
		  }
	} catch (error) {
		yield put(getOrderDetailError(error));
	}
}

const ordersFilterRequest = async (payload) => {
  let value; 
  if((payload.column == "createdAt")){
    try{
      let date = new Date(payload.value);
      let year = date.getFullYear();
      let month = date.getMonth()+1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      console.log(year+'-' + month + '-'+dt);
      value = year+'-' + month + '-'+dt
    }catch(err){
      console.log(err)
    }
  }else{
    value = payload.value
  }
  let options =  JSON.stringify({ "val" : value, "key" : payload.column,"type":"orders"});
  return await axios.get(`${internal_API+apiUrl_filter}?search=${options}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
};

function* getFilteredOrders({payload}) {
  try {
    const response = yield call(ordersFilterRequest ,payload);
    if(response && response.status === 200){
      yield put(getOrdersListWithFilterSuccess(response.data));
    }else{
      yield put(getOrdersListWithFilterError(response));
    }
  } catch (error) {
    yield put(getOrdersListWithFilterError("Not Found"));
  }
}

const updateOrderRequest = async itemObj => {
  let itemId = itemObj.itemId;
  var body = {};
  if(itemObj.trackNumber){
    body["trackNumber"] = itemObj.trackNumber
  }
  if(itemObj.status){
    body["status"] = itemObj.status
  }
	return await axios.put(`${internal_API+apiUrl_order}/${itemId}`,body,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* updateOrder({payload}) {
  try {
    const response = yield call(updateOrderRequest ,payload);
    if(response && (response.status === 200 || response.status === 201)){
      yield put(updateOrderSuccess(response.data));
    }else{
      yield put(updateOrderError(response));
    }
  } catch (error) {
    yield put(updateOrderError("Not Found"));
  }
}

export function* watchGetOrdersList() {
  yield takeEvery(ORDERS_GET_LIST, getOrdersList);
}

export function* watchGetAlpha() {
  yield takeEvery(ALPHA_GET, getAlpha);
}

export function* watchUpdateAlpha() {
  yield takeEvery(ALPHA_UPDATE, updateAlpha);
}

export function* watchGetAndroid() {
  yield takeEvery(ANDROID_GET, getAndroid);
}

export function* watchGetOrderDetail() {
  yield takeEvery(ORDER_GET_DETAILS, getOrderDetail);
}

export function* watchDeleteOrder() {
  yield takeEvery(ORDER_DELETE, deleteOrder);
}

export function* watchGetFilteredOrders() {
  yield takeEvery(ORDERS_GET_LIST_WITH_FILTER, getFilteredOrders);
}
export function* watchGetUpdateOrder() {
  yield takeEvery(ORDER_UPDATE, updateOrder);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetOrdersList),
    fork(watchGetAlpha),
    fork(watchUpdateAlpha),
    fork(watchGetAndroid),
    fork(watchGetOrderDetail),
    fork(watchDeleteOrder),
    fork(watchGetFilteredOrders),
    fork(watchGetUpdateOrder)
  ]);
}
