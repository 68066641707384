import {
    MISSIONS_GET_LIST,
    MISSIONS_GET_LIST_SUCCESS,
    MISSIONS_GET_LIST_ERROR,
    MISSION_GET_DETAILS,
    MISSION_GET_DETAILS_SUCCESS,
    MISSION_GET_DETAILS_ERROR,
    MISSION_DELETE,
    MISSION_DELETE_SUCCESS,
    MISSIONS_GET_LIST_WITH_FILTER,
    MISSIONS_GET_LIST_WITH_FILTER_SUCCESS,
	MISSIONS_GET_LIST_WITH_FILTER_ERROR,
	MISSION_ADD_ITEM,
	MISSION_ADD_ITEM_SUCCESS,
	MISSION_ADD_ITEM_ERROR,
	MISSION_ITEM_UPDATE_DETAILS,
	MISSION_ITEM_UPDATE_DETAILS_SUCCESS,
	MISSION_ITEM_UPDATE_DETAILS_ERROR,
	GET_AVAILABLE_LEVELS,
	GET_AVAILABLE_LEVELS_SUCCESS,
	GET_AVAILABLE_LEVELS_ERROR
} from "../actions.js"

const INIT_STATE = {
	missions: null,
	error: '',
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	categories: [],
	selectedOrders: [],
	loadingLevels:false,
	availableLevels: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case MISSIONS_GET_LIST:
			return { ...state, loading: false };

		case MISSIONS_GET_LIST_SUCCESS:
			return { ...state, loading: true, missions: action.payload};

		case MISSIONS_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };

		case MISSION_GET_DETAILS:
			return { ...state, isLoading: false };

		case MISSION_GET_DETAILS_SUCCESS:
			return { ...state, isLoading: true, mission: action.payload};

		case MISSION_GET_DETAILS_ERROR:
			return { ...state, isLoading: true, error: action.payload };

		case MISSIONS_GET_LIST_WITH_FILTER:
			return { ...state, loading: false};

		case MISSIONS_GET_LIST_WITH_FILTER_SUCCESS:
			return { ...state, loading: true, missions: action.payload};
		
		case MISSIONS_GET_LIST_WITH_FILTER_ERROR:
			return { ...state, loading: true, error: action.payload };

		case MISSION_DELETE:
			return { ...state,isLoading:false};

		case MISSION_DELETE_SUCCESS:
			return { ...state, isLoading: true, order: action.payload};

		case MISSION_ADD_ITEM:
			return { ...state, loading: false };

		case MISSION_ADD_ITEM_SUCCESS:
			return { ...state, loading: true, missions: action.payload};

		case MISSION_ADD_ITEM_ERROR:
			return { ...state, loading: true, error: action.payload };
			
		case MISSION_ITEM_UPDATE_DETAILS:
			return { ...state,loading:false};

		case MISSION_ITEM_UPDATE_DETAILS_SUCCESS:
			return { ...state, loading: true, mission: action.payload};

		case MISSION_ITEM_UPDATE_DETAILS_ERROR:
			return { ...state, loading: true, error: action.payload };
		
		case GET_AVAILABLE_LEVELS:
			return { ...state, loadingLevels: false };

		case GET_AVAILABLE_LEVELS_SUCCESS:
			return { ...state, loadingLevels: true, availableLevels: action.payload};

		case GET_AVAILABLE_LEVELS_ERROR:
			return { ...state, loadingLevels: true, availableLevelsError: action.payload };


		default: return { ...state };

	}
}
