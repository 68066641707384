import {
    Statistics_GET_LIST,
    Statistics_GET_LIST_SUCCESS,
    Statistics_GET_LIST_ERROR
} from "../actions.js"

export const getStatisticsList = () => ({
    type: Statistics_GET_LIST
});

export const getStatisticsListSuccess = (items) => ({
    type: Statistics_GET_LIST_SUCCESS,
    payload: items
});

export const getStatisticsListError = (error) => ({
    type: Statistics_GET_LIST_ERROR,
    payload: error
});
