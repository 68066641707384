import {
    Statistics_GET_LIST,
    Statistics_GET_LIST_SUCCESS,
    Statistics_GET_LIST_ERROR
} from "../actions.js"

const INIT_STATE = {
	statisticsList: null,
	error: '',
	loading: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case Statistics_GET_LIST:
			return { ...state, loading: false };

		case Statistics_GET_LIST_SUCCESS:
			return { ...state, loading: true, statisticsList: action.payload};

		case Statistics_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };


		default: return { ...state };

	}
}
