import {
  MatchedCars_GET_LIST,
  MatchedCars_GET_LIST_SUCCESS,
  MatchedCars_GET_LIST_ERROR,
  MatchedCars_GET_LIST_WITH_FILTER,
  MatchedCars_GET_LIST_WITH_FILTER_SUCCESS,
  MatchedCars_GET_LIST_WITH_FILTER_ERROR,
  MatchedCar_GET_DETAILS,
  MatchedCar_GET_DETAILS_SUCCESS,
  MatchedCar_GET_DETAILS_ERROR,
  Track_GET,
  Track_GET_SUCCESS,
  Track_GET_ERROR,
  MatchedCars_ALL,
  MatchedCars_ALL_SUCCESS,
  MatchedCars_ALL_ERROR,
} from "../actions";

const INIT_STATE = {
  matchedCarsList: null,
  matchedCar: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  categories: [],
  selectedMatchedCars: [],
  matchedCars: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MatchedCars_GET_LIST:
      return { ...state, loading: false };

    case MatchedCars_GET_LIST_SUCCESS:
      return { ...state, loading: true, matchedCarsList: action.payload };

    case MatchedCars_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case MatchedCars_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case MatchedCars_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, matchedCarsList: action.payload };

    case MatchedCars_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case MatchedCar_GET_DETAILS:
      return { ...state, isLoading: false };

    case MatchedCar_GET_DETAILS_SUCCESS:
      return { ...state, isLoading: true, matchedCar: action.payload };

    case MatchedCar_GET_DETAILS_ERROR:
      return { ...state, isLoading: true, error: action.payload };
    case Track_GET:
      return { ...state, isLoadingTrack: false };

    case Track_GET_SUCCESS:
      return { ...state, isLoadingTrack: true, trackInfo: action.payload };

    case Track_GET_ERROR:
      return { ...state, isLoadingTrack: true, error: action.payload };
    case MatchedCars_ALL:
      return { ...state, loadingAll: false };

    case MatchedCars_ALL_SUCCESS:
      return { ...state, loadingAll: true, matchedCars: action.payload };

    case MatchedCars_ALL_ERROR:
      return { ...state, loadingAll: true, error: action.payload };
    default:
      return { ...state };
  }
};
