import {
  Lienholder_GET_LIST,
  Lienholder_GET_LIST_SUCCESS,
  Lienholder_GET_LIST_ERROR,
  Lienholder_GET_DETAILS,
  Lienholder_GET_DETAILS_SUCCESS,
  Lienholder_GET_DETAILS_ERROR,
  Lienholder_DELETE,
  Lienholder_DELETE_SUCCESS,
  Lienholder_GET_LIST_WITH_FILTER,
  Lienholder_GET_LIST_WITH_FILTER_SUCCESS,
  Lienholder_GET_LIST_WITH_FILTER_ERROR,
  Lienholder_ITEM_UPDATE_DETAILS,
  Lienholder_ITEM_UPDATE_DETAILS_SUCCESS,
  Lienholder_ITEM_UPDATE_DETAILS_ERROR,
} from "../actions.js";

const INIT_STATE = {
  LienholderList: null,
  Lienholder: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  categories: [],
  selectedOrders: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Lienholder_GET_LIST:
      return { ...state, loading: false };

    case Lienholder_GET_LIST_SUCCESS:
      return { ...state, loading: true, LienholderList: action.payload };

    case Lienholder_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Lienholder_GET_DETAILS:
      return { ...state, isLoading: false };

    case Lienholder_GET_DETAILS_SUCCESS:
      return { ...state, isLoading: true, Lienholder: action.payload };

    case Lienholder_GET_DETAILS_ERROR:
      return { ...state, isLoading: true, error: action.payload };

    case Lienholder_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case Lienholder_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, LienholderList: action.payload };

    case Lienholder_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Lienholder_DELETE:
      return { ...state, isLoading: false };

    case Lienholder_DELETE_SUCCESS:
      return { ...state, isLoading: true, Lienholder: action.payload };

    case Lienholder_ITEM_UPDATE_DETAILS:
      return { ...state, loading: false };

    case Lienholder_ITEM_UPDATE_DETAILS_SUCCESS:
      return { ...state, loading: true, Lienholder: action.payload };

    case Lienholder_ITEM_UPDATE_DETAILS_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
