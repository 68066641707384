import {
    STORE_GET_DETAILS,
    STORE_GET_DETAILS_SUCCESS,
	STORE_GET_DETAILS_ERROR,
	STORE_ITEM_DELETE,
	STORE_ITEM_UPDATE_DETAILS,
	STORE_ITEM_UPDATE_DETAILS_SUCCESS,
	STORE_ITEM_UPDATE_DETAILS_ERROR,
	STORE_ITEM_DELETE_SUCCESS
} from '../actions';

const INIT_STATE = {
	storeItem:null,
	loading:false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case STORE_GET_DETAILS:
			return { ...state, loading: false };

		case STORE_GET_DETAILS_SUCCESS:
			return { ...state, loading: true, storeItem: action.payload};

		case STORE_GET_DETAILS_ERROR:
			return { ...state, loading: true, error: action.payload };

		case STORE_ITEM_UPDATE_DETAILS:
			return { ...state,loading:false};

		case STORE_ITEM_UPDATE_DETAILS_SUCCESS:
			return { ...state, loading: true, storeItem: action.payload};

		case STORE_ITEM_UPDATE_DETAILS_ERROR:
			return { ...state, loading: true, error: action.payload };

		case STORE_ITEM_DELETE:
			return { ...state,loading:false};

		case STORE_ITEM_DELETE_SUCCESS:
			return { ...state, loading: true, storeItem: action.payload};

		default: return { ...state };
	}
}
