/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_EMAIL = "RESET_PASSWORD_EMAIL";
export const RESET_PASSWORD_EMAIL_SUCCESS = "RESET_PASSWORD_EMAIL_SUCCESS";
export const RESET_PASSWORD_EMAIL_ERROR = "RESET_PASSWORD_EMAIL_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* STORE List*/
export const STORE_LIST_GET_LIST = "STORE_LIST_GET_LIST";
export const STORE_LIST_GET_LIST_SUCCESS = "STORE_LIST_GET_LIST_SUCCESS";
export const STORE_LIST_GET_LIST_ERROR = "STORE_LIST_GET_LIST_ERROR";
export const STORE_LIST_GET_LIST_SEARCH = "STORE_LIST_GET_LIST_SEARCH";
export const STORE_LIST_ADD_ITEM = "STORE_LIST_ADD_ITEM";
export const STORE_LIST_ADD_ITEM_SUCCESS = "STORE_LIST_ADD_ITEM_SUCCESS";
export const STORE_LIST_ADD_ITEM_ERROR = "STORE_LIST_ADD_ITEM_ERROR";
export const STORE_LIST_SELECTED_ITEMS_CHANGE =
  "STORE_LIST_SELECTED_ITEMS_CHANGE";

/* STORE DETAIL APP*/
export const STORE_GET_DETAILS = "STORE_GET_DETAILS";
export const STORE_GET_DETAILS_SUCCESS = "STORE_GET_DETAILS_SUCCESS";
export const STORE_GET_DETAILS_ERROR = "STORE_GET_DETAILS_ERROR";
export const STORE_DELETE_QUESTION = "STORE_DELETE_QUESTION";
export const STORE_SAVE = "STORE_SAVE";
export const STORE_ITEM_UPDATE_DETAILS = "STORE_ITEM_UPDATE_DETAILS";
export const STORE_ITEM_UPDATE_DETAILS_SUCCESS =
  "STORE_ITEM_UPDATE_DETAILS_SUCCESS";
export const STORE_ITEM_UPDATE_DETAILS_ERROR =
  "STORE_ITEM_UPDATE_DETAILS_ERROR";
export const STORE_ITEM_DELETE = "STORE_ITEM_DELETE";
export const STORE_ITEM_DELETE_SUCCESS = "STORE_ITEM_DELETE_SUCCESS";

/* Requested Cars List & Detail*/

export const ReqCARS_GET_LIST = "ReqCARS_GET_LIST";
export const ReqCARS_GET_LIST_SUCCESS = "ReqCARS_GET_LIST_SUCCESS";
export const ReqCARS_GET_LIST_ERROR = "ReqCARS_GET_LIST_ERROR";
export const ReqCARS_GET_LIST_WITH_FILTER = "ReqCARS_GET_LIST_WITH_FILTER";
export const ReqCARS_GET_LIST_WITH_ORDER = "ReqCARS_GET_LIST_WITH_ORDER";
export const ReqCARS_GET_LIST_SEARCH = "ReqCARS_GET_LIST_SEARCH";
export const ReqCARS_ADD_ITEM = "ReqCARS_ADD_ITEM";
export const ReqCARS_ADD_ITEM_SUCCESS = "ReqCARS_ADD_ITEM_SUCCESS";
export const ReqCARS_ADD_ITEM_ERROR = "ReqCARS_ADD_ITEM_ERROR";
export const ReqCARS_SELECTED_ITEMS_CHANGE = "ReqCARS_SELECTED_ITEMS_CHANGE";
export const ReqCARS_GET_LIST_WITH_FILTER_SUCCESS =
  "ReqCARS_GET_LIST_WITH_FILTER_SUCCESS";
export const ReqCARS_GET_LIST_WITH_FILTER_ERROR =
  "ReqCARS_GET_LIST_WITH_FILTER_ERROR";
export const ReqCAR_GET_DETAIL = "ReqCAR_GET_DETAIL";
export const ReqCAR_GET_DETAIL_SUCCESS = "ReqCAR_GET_DETAIL_SUCCESS";
export const ReqCAR_GET_DETAIL_ERROR = "ReqCAR_GET_DETAIL_ERROR";
export const ReqCAR_ITEM_UPDATE_DETAILS = "ReqCAR_ITEM_UPDATE_DETAILS";
export const ReqCAR_ITEM_UPDATE_DETAILS_SUCCESS =
  "ReqCAR_ITEM_UPDATE_DETAILS_SUCCESS";
export const ReqCAR_ITEM_UPDATE_DETAILS_ERROR =
  "ReqCAR_ITEM_UPDATE_DETAILS_ERROR";
export const ReqCAR_ITEM_DELETE = "ReqCAR_ITEM_DELETE";
export const ReqCAR_ITEM_DELETE_SUCCESS = "ReqCAR_ITEM_DELETE_SUCCESS";
export const ReqCARS_GET_LIST_ALL = "ReqCARS_GET_LIST_ALL";
export const ReqCARS_GET_LIST_SUCCESS_ALL = "ReqCARS_GET_LIST_SUCCESS_ALL";
export const ReqCARS_GET_LIST_ERROR_ALL = "ReqCARS_GET_LIST_ERROR_ALL";
export const ReqCARS_GET_ALL = "ReqCARS_GET_ALL";
export const ReqCARS_GET_ALL_SUCCESS = "ReqCARS_GET_ALL_SUCCESS";
export const ReqCARS_GET_ALL_ERROR = "ReqCARS_GET_ALL_ERROR";

/* Orders List & detail*/
export const ORDERS_GET_LIST = "ORDERS_GET_LIST";
export const ORDERS_GET_LIST_SUCCESS = "ORDERS_GET_LIST_SUCCESS";
export const ORDERS_GET_LIST_ERROR = "ORDERS_GET_LIST_ERROR";

export const ALPHA_GET = "ALPHA_GET";
export const ALPHA_GET_SUCCESS = "ALPHA_GET_SUCCESS";
export const ALPHA_GET_ERROR = "ALPHA_GET_ERROR";
export const ALPHA_UPDATE = "ALPHA_UPDATE";
export const ALPHA_UPDATE_SUCCESS = "ALPHA_UPDATE_SUCCESS";
export const ALPHA_UPDATE_ERROR = "ALPHA_UPDATE_ERROR";
export const ANDROID_GET = "ANDROID_GET";
export const ANDROID_GET_SUCCESS = "ANDROID_GET_SUCCESS";
export const ANDROID_GET_ERROR = "ANDROID_GET_ERROR";

export const ORDER_GET_DETAILS = "ORDER_GET_DETAILS";
export const ORDER_GET_DETAILS_SUCCESS = "ORDER_GET_DETAILS_SUCCESS";
export const ORDER_GET_DETAILS_ERROR = "ORDER_GET_DETAILS_ERROR";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDERS_GET_LIST_WITH_FILTER = "ORDERS_GET_LIST_WITH_FILTER";
export const ORDERS_GET_LIST_WITH_FILTER_SUCCESS =
  "ORDERS_GET_LIST_WITH_FILTER_SUCCESS";
export const ORDERS_GET_LIST_WITH_FILTER_ERROR =
  "ORDERS_GET_LIST_WITH_FILTER_ERROR";

/* Missions List */
export const MISSIONS_GET_LIST = "MISSIONS_GET_LIST";
export const MISSIONS_GET_LIST_SUCCESS = "MISSIONS_GET_LIST_SUCCESS";
export const MISSIONS_GET_LIST_ERROR = "MISSIONS_GET_LIST_ERROR";
export const MISSION_GET_DETAILS = "MISSION_GET_DETAILS";
export const MISSION_GET_DETAILS_SUCCESS = "MISSION_GET_DETAILS_SUCCESS";
export const MISSION_GET_DETAILS_ERROR = "MISSION_GET_DETAILS_ERROR";
export const MISSION_DELETE = "MISSION_DELETE";
export const MISSION_DELETE_SUCCESS = "MISSION_DELETE_SUCCESS";
export const MISSIONS_GET_LIST_WITH_FILTER = "MISSIONS_GET_LIST_WITH_FILTER";
export const MISSIONS_GET_LIST_WITH_FILTER_SUCCESS =
  "MISSIONS_GET_LIST_WITH_FILTER_SUCCESS";
export const MISSIONS_GET_LIST_WITH_FILTER_ERROR =
  "MISSIONS_GET_LIST_WITH_FILTER_ERROR";
export const MISSION_ADD_ITEM = "MISSION_ADD_ITEM";
export const MISSION_ADD_ITEM_SUCCESS = "MISSION_ADD_ITEM_SUCCESS";
export const MISSION_ADD_ITEM_ERROR = "MISSION_ADD_ITEM_ERROR";
export const MISSION_ITEM_UPDATE_DETAILS = "MISSION_ITEM_UPDATE_DETAILS";
export const MISSION_ITEM_UPDATE_DETAILS_SUCCESS =
  "MISSION_ITEM_UPDATE_DETAILS_SUCCESS";
export const MISSION_ITEM_UPDATE_DETAILS_ERROR =
  "MISSION_ITEM_UPDATE_DETAILS_ERROR";

/* Scanned Cars List*/
export const ScannedCars_GET_LIST = "ScannedCars_GET_LIST";
export const ScannedCars_GET_LIST_SUCCESS = "ScannedCars_GET_LIST_SUCCESS";
export const ScannedCars_GET_LIST_ERROR = "ScannedCars_GET_LIST_ERROR";
export const ScannedCars_GET_LIST_WITH_FILTER =
  "ScannedCars_GET_LIST_WITH_FILTER";
export const ScannedCars_GET_LIST_WITH_FILTER_SUCCESS =
  "ScannedCars_GET_LIST_WITH_FILTER_SUCCESS";
export const ScannedCars_GET_LIST_WITH_FILTER_ERROR =
  "ScannedCars_GET_LIST_WITH_FILTER_ERROR";
export const ScannedCar_GET_DETAILS = "ScannedCar_GET_DETAILS";
export const ScannedCar_GET_DETAILS_SUCCESS = "ScannedCar_GET_DETAILS_SUCCESS";
export const ScannedCar_GET_DETAILS_ERROR = "ScannedCar_GET_DETAILS_ERROR";
export const ScannedCar_GET_ALL = "ScannedCar_GET_ALL";
export const ScannedCar_GET_ALL_SUCCESS = "ScannedCar_GET_ALL_SUCCESS";
export const ScannedCar_GET_ALL_ERROR = "ScannedCar_GET_ALL_ERROR";

/* Matched Cars */
export const MatchedCars_GET_LIST = "MatchedCars_GET_LIST";
export const MatchedCars_GET_LIST_SUCCESS = "MatchedCars_GET_LIST_SUCCESS";
export const MatchedCars_GET_LIST_ERROR = "MatchedCars_GET_LIST_ERROR";
export const MatchedCars_GET_LIST_WITH_FILTER =
  "MatchedCars_GET_LIST_WITH_FILTER";
export const MatchedCars_GET_LIST_WITH_FILTER_SUCCESS =
  "MatchedCars_GET_LIST_WITH_FILTER_SUCCESS";
export const MatchedCars_GET_LIST_WITH_FILTER_ERROR =
  "MatchedCars_GET_LIST_WITH_FILTER_ERROR";
export const MatchedCar_GET_DETAILS = "MatchedCar_GET_DETAILS";
export const MatchedCar_GET_DETAILS_SUCCESS = "MatchedCar_GET_DETAILS_SUCCESS";
export const MatchedCar_GET_DETAILS_ERROR = "MatchedCar_GET_DETAILS_ERROR";
export const MatchedCars_ALL = "MatchedCars_ALL";
export const MatchedCars_ALL_SUCCESS = "MatchedCars_ALL_SUCCESS";
export const MatchedCars_ALL_ERROR = "MatchedCars_ALL_ERROR";

export const Track_GET = "Track_GET";
export const Track_GET_SUCCESS = "Track_GET_SUCCESS";
export const Track_GET_ERROR = "Track_GET_ERROR";

/*Levels */
export const Levels_GET_LIST = "Levels_GET_LIST";
export const Levels_GET_LIST_SUCCESS = "Levels_GET_LIST_SUCCESS";
export const Levels_GET_LIST_ERROR = "Levels_GET_LIST_ERROR";
export const Level_GET_DETAILS = "Level_GET_DETAILS";
export const Level_GET_DETAILS_SUCCESS = "Level_GET_DETAILS_SUCCESS";
export const Level_GET_DETAILS_ERROR = "Level_GET_DETAILS_ERROR";
export const Level_DELETE = "Level_DELETE";
export const Level_DELETE_SUCCESS = "Level_DELETE_SUCCESS";
export const Levels_GET_LIST_WITH_FILTER = "Levels_GET_LIST_WITH_FILTER";
export const Levels_GET_LIST_WITH_FILTER_SUCCESS =
  "Levels_GET_LIST_WITH_FILTER_SUCCESS";
export const Levels_GET_LIST_WITH_FILTER_ERROR =
  "Levels_GET_LIST_WITH_FILTER_ERROR";
export const Level_ADD_ITEM = "Level_ADD_ITEM";
export const Level_ADD_ITEM_SUCCESS = "Level_ADD_ITEM_SUCCESS";
export const Level_ADD_ITEM_ERROR = "Level_ADD_ITEM_ERROR";
export const Level_ITEM_UPDATE_DETAILS = "Level_ITEM_UPDATE_DETAILS";
export const Level_ITEM_UPDATE_DETAILS_SUCCESS =
  "Level_ITEM_UPDATE_DETAILS_SUCCESS";
export const Level_ITEM_UPDATE_DETAILS_ERROR =
  "Level_ITEM_UPDATE_DETAILS_ERROR";
export const GET_AVAILABLE_LEVELS = "GET_AVAILABLE_LEVELS";
export const GET_AVAILABLE_LEVELS_SUCCESS = "GET_AVAILABLE_LEVELS_SUCCESS";
export const GET_AVAILABLE_LEVELS_ERROR = "GET_AVAILABLE_LEVELS_ERROR";

/*Users Missions */
export const UsersMissions_GET_LIST = "UsersMissions_GET_LIST";
export const UsersMissions_GET_LIST_SUCCESS = "UsersMissions_GET_LIST_SUCCESS";
export const UsersMissions_GET_LIST_ERROR = "UsersMissions_GET_LIST_ERROR";
export const UsersMissions_GET_LIST_WITH_FILTER =
  "UsersMissions_GET_LIST_WITH_FILTER";
export const UsersMissions_GET_LIST_WITH_FILTER_SUCCESS =
  "UsersMissions_GET_LIST_WITH_FILTER_SUCCESS";
export const UsersMissions_GET_LIST_WITH_FILTER_ERROR =
  "UsersMissions_GET_LIST_WITH_FILTER_ERROR";

/* Configurations*/
export const Configurations_GET_LIST = "Configurations_GET_LIST";
export const Configurations_GET_LIST_SUCCESS =
  "Configurations_GET_LIST_SUCCESS";
export const Configurations_GET_LIST_ERROR = "Configurations_GET_LIST_ERROR";
export const Configurations_UPDATE = "Configurations_UPDATE";
export const Configurations_UPDATE_SUCCESS = "Configurations_UPDATE_SUCCESS";
export const Configurations_UPDATE_ERROR = "Configurations_UPDATE_ERROR";

/* Statistics */
export const Statistics_GET_LIST = "Statistics_GET_LIST";
export const Statistics_GET_LIST_SUCCESS = "Statistics_GET_LIST_SUCCESS";
export const Statistics_GET_LIST_ERROR = "Statistics_GET_LIST_ERROR";

/*Users */
export const Users_GET_LIST = "Users_GET_LIST";
export const Users_GET_LIST_SUCCESS = "Users_GET_LIST_SUCCESS";
export const Users_GET_LIST_ERROR = "Users_GET_LIST_ERROR";
export const Users_GET_LIST_WITH_FILTER = "Users_GET_LIST_WITH_FILTER";
export const Users_GET_LIST_WITH_FILTER_SUCCESS =
  "Users_GET_LIST_WITH_FILTER_SUCCESS";
export const Users_GET_LIST_WITH_FILTER_ERROR =
  "Users_GET_LIST_WITH_FILTER_ERROR";
export const User_LOGS_GET = "User_LOGS_GET";
export const User_LOGS_GET_SUCCESS = "User_LOGS_GET_SUCCESS";
export const User_LOGS_GET_ERROR = "User_LOGS_GET_ERROR";

export const Users_GET_ALL = "Users_GET_ALL";
export const Users_GET_ALL_SUCCESS = "Users_GET_ALL_SUCCESS";
export const Users_GET_ALL_ERROR = "Users_GET_ALL_ERROR";

export const ORDER_UPDATE = "ORDER_UPDATE";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_ERROR = "ORDER_UPDATE_ERROR";

/*Users */
export const Pending_GET_LIST = "Pending_GET_LIST";
export const Pending_GET_LIST_SUCCESS = "Pending_GET_LIST_SUCCESS";
export const Pending_GET_LIST_ERROR = "Pending_GET_LIST_ERROR";
export const Pending_GET_LIST_WITH_FILTER = "Pending_GET_LIST_WITH_FILTER";
export const Pending_GET_LIST_WITH_FILTER_SUCCESS =
  "Pending_GET_LIST_WITH_FILTER_SUCCESS";
export const Pending_GET_LIST_WITH_FILTER_ERROR =
  "Pending_GET_LIST_WITH_FILTER_ERROR";
export const Approved_GET_LIST = "Approved_GET_LIST";
export const Approved_GET_LIST_SUCCESS = "Approved_GET_LIST_SUCCESS";
export const Approved_GET_LIST_ERROR = "Approved_GET_LIST_ERROR";
export const Approved_GET_LIST_WITH_FILTER = "Approved_GET_LIST_WITH_FILTER";
export const Approved_GET_LIST_WITH_FILTER_SUCCESS =
  "Approved_GET_LIST_WITH_FILTER_SUCCESS";
export const Approved_GET_LIST_WITH_FILTER_ERROR =
  "Approved_GET_LIST_WITH_FILTER_ERROR";
export const Rejected_GET_LIST = "Rejected_GET_LIST";
export const Rejected_GET_LIST_SUCCESS = "Rejected_GET_LIST_SUCCESS";
export const Rejected_GET_LIST_ERROR = "Rejected_GET_LIST_ERROR";
export const Rejected_GET_LIST_WITH_FILTER = "Rejected_GET_LIST_WITH_FILTER";
export const Rejected_GET_LIST_WITH_FILTER_SUCCESS =
  "Rejected_GET_LIST_WITH_FILTER_SUCCESS";
export const Rejected_GET_LIST_WITH_FILTER_ERROR =
  "Rejected_GET_LIST_WITH_FILTER_ERROR";
export const Company_UPDATE = "Company_UPDATE";
export const Company_UPDATE_SUCCESS = "Company_UPDATE_SUCCESS";
export const Company_UPDATE_ERROR = "Company_UPDATE_ERROR";
export const Get_Company_Detail = "Get_Company_Detail";
export const Get_Company_Detail_SUCCESS = "Get_Company_Detail_SUCCESS";
export const Get_Company_Detail_ERROR = "Get_Company_Detail_ERROR";
export const Get_Vehicles = "Get_Vehicles";
export const Get_Vehicles_SUCCESS = "Get_Vehicles_SUCCESS";
export const Get_Vehicles_ERROR = "Get_Vehicles_ERROR";
export const Get_Storages = "Get_Storages";
export const Get_Storages_SUCCESS = "Get_Storages_SUCCESS";
export const Get_Storages_ERROR = "Get_Storages_ERROR";

export const Get_Drivers = "Get_Drivers";
export const Get_Drivers_SUCCESS = "Get_Drivers_SUCCESS";
export const Get_Drivers_ERROR = "Get_Drivers_ERROR";

export const Company_GET_LIST_WITH_FILTER = "Company_GET_LIST_WITH_FILTER";
export const Company_GET_LIST_WITH_FILTER_SUCCESS =
  "Company_GET_LIST_WITH_FILTER_SUCCESS";
export const Company_GET_LIST_WITH_FILTER_ERROR =
  "Company_GET_LIST_WITH_FILTER_ERROR";

export const Lienholder_GET_LIST = "Lienholder_GET_LIST";
export const Lienholder_GET_DETAILS = "Lienholder_GET_DETAILS";
export const Lienholder_DELETE = "Lienholder_DELETE";
export const Lienholder_GET_LIST_WITH_FILTER =
  "Lienholder_GET_LIST_WITH_FILTER";
export const Lienholder_ITEM_UPDATE_DETAILS = "Lienholder_ITEM_UPDATE_DETAILS";
export const Lienholder_DELETE_SUCCESS = "Lienholder_DELETE_SUCCESS";
export const Lienholder_GET_DETAILS_ERROR = "Lienholder_GET_DETAILS_ERROR";
export const Lienholder_GET_DETAILS_SUCCESS = "Lienholder_GET_DETAILS_SUCCESS";
export const Lienholder_GET_LIST_ERROR = "Lienholder_GET_LIST_ERROR";
export const Lienholder_GET_LIST_SUCCESS = "Lienholder_GET_LIST_SUCCESS";
export const Lienholder_GET_LIST_WITH_FILTER_ERROR =
  "Lienholder_GET_LIST_WITH_FILTER_ERROR";
export const Lienholder_GET_LIST_WITH_FILTER_SUCCESS =
  "Lienholder_GET_LIST_WITH_FILTER_SUCCESS";
export const Lienholder_ITEM_UPDATE_DETAILS_ERROR =
  "Lienholder_ITEM_UPDATE_DETAILS_ERROR";
export const Lienholder_ITEM_UPDATE_DETAILS_SUCCESS =
  "Lienholder_ITEM_UPDATE_DETAILS_SUCCESS";

export const Company_DELETE = "Company_DELETE";
export const Company_DELETE_SUCCESS = "Company_DELETE_SUCCESS";
export const Company_DELETE_ERROR = "Company_DELETE_ERROR";

export const Config_Driver = "Config_Driver";
export const Config_Driver_SUCCESS = "Config_Driver_SUCCESS";
export const Config_Driver_ERROR = "Config_Driver_ERROR";
export const Config_Driver_UPDATE = "Config_Driver_UPDATE";
export const Config_Driver_UPDATE_SUCCESS = "Config_Driver_UPDATE_SUCCESS";
export const Config_Driver_UPDATE_ERROR = "Config_Driver_UPDATE_ERROR";


export const Get_Handovers = "Get_Handovers";
export const Get_Handovers_SUCCESS = "Get_Handovers_SUCCESS";
export const Get_Handovers_ERROR = "Get_Handovers_ERROR";
export const Get_Handover = "Get_Handover";
export const Get_Handover_SUCCESS = "Get_Handover_SUCCESS";
export const Get_Handover_ERROR = "Get_Handover_ERROR";
export const Update_Handover = "Update_Handover";
export const Update_Handover_SUCCESS = "Update_Handover_SUCCESS";
export const Update_Handover_ERROR = "Update_Handover_ERROR";
export const Get_Fees = "Get_Fees";
export const Get_Fees_SUCCESS = "Get_Fees_SUCCESS";
export const Get_Fees_ERROR = "Get_Fees_ERROR";
export const Create_Fees = "Create_Fees";
export const Create_Fees_SUCCESS = "Create_Fees_SUCCESS";
export const Create_Fees_ERROR = "Create_Fees_ERROR";
export const Update_Fees = "Update_Fees";
export const Update_Fees_SUCCESS = "Update_Fees_SUCCESS";
export const Update_Fees_ERROR = "Update_Fees_ERROR";
export const Delete_Fees = "Delete_Fees";
export const Delete_Fees_SUCCESS = "Delete_Fees_SUCCESS";
export const Delete_Fees_ERROR = "Delete_Fees_ERROR";


export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./store/actions";
export * from "./requestedCarsList/actions";
export * from "./missions/actions";
export * from "./scannedCars/actions";
export * from "./matchedCars/actions";
export * from "./levels/actions";
export * from "./usersMissions/actions";
export * from "./configurations/actions";
export * from "./statistics/actions";
export * from "./users/actions";
export * from "./companies/actions";
export * from "./lienholder/actions";
