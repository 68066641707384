import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { internal_API ,apiUrl_filter, apiUrl_Levels, apiUrl_LevelInfo} from "../../constants/defaultValues";

import {
    Levels_GET_LIST,
    Level_GET_DETAILS,
    Level_DELETE,
    Levels_GET_LIST_WITH_FILTER,
    Level_ADD_ITEM,
    Level_ITEM_UPDATE_DETAILS
} from "../actions.js"

import {
    getLevelsListSuccess,
    getLevelsListError,
    getLevelsListWithFilterSuccess,
    getLevelsListWithFilterError,
    getLevelDetailError,
    getLevelDetailSuccess,
    addLevelSuccess,
    addLevelError,
    updateLevelSuccess,
    updateLevelError,
    deleteLevelSuccess
  } from "./actions";

  import axios from 'axios';


const LevelsRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_Levels}`,{
          headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
          }
      })
      .then(response => response)
      .catch(error => error);
};
  
function* getLevels() {
  try {
      const response = yield call(LevelsRequest);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(getLevelsListSuccess(response.data));
      }else{
        yield put(getLevelsListError(response));
      }
  } catch (error) {
        yield put(getLevelsListError(error));
  }
}

const getLevelDetailRequest = async item => {
  let itemId = item.payload;
	return await axios.get(`${internal_API+apiUrl_LevelInfo}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* getLevelDetail(payload) {
	try {
    const response = yield call(getLevelDetailRequest, payload);
		if(response && (response.status === 200 || response.status == 201)){
			yield put(getLevelDetailSuccess(response.data));
		}else{
			yield put(getLevelDetailError(response));
		}
	} catch (error) {
		yield put(getLevelDetailError(error));
	}
}
const addLevelRequest = async item => {
    const options = {
      headers: {
        "x-auth-token": localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json'
        }
    };
    var body = {
      name: item.name,
      status: item.status,
      locked:false
    };
    return await axios.post(`${internal_API+apiUrl_Levels}`,body,options)
  .then(async (response) => {
    if(response && (response.status === 200 || response.status === 201)){
      return await axios.get(`${internal_API+apiUrl_Levels}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    }else{
      return response
    }
  })
  .catch(error => error);
  };
  
  function* addLevel({ payload }) {
    try {
      const response = yield call(addLevelRequest, payload);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(addLevelSuccess(response.data));
      }else{
        yield put(addLevelError(response));
      }
    } catch (error) {
      yield put(addLevelError(error));
    }
  }


const deleteLevelRequest = async (itemId, item) => {
	return await axios.delete(`${internal_API+apiUrl_Levels}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* deleteLevel({ payload }) {
	try {
    const { itemId, item } = payload;
		const response = yield call(deleteLevelRequest, itemId, item);
		if(response && (response.status === 200 || response.status === 201)){
			yield put(deleteLevelSuccess(response));
		  }else{
			yield put(getLevelDetailError(response));
		  }
	} catch (error) {
		yield put(getLevelDetailError(error));
	}
}

const LevelsFilterRequest = async (payload) => {
  let options =  JSON.stringify({ "val" :payload.value, "key" : payload.column,"type":"levels"});
  return await axios.get(`${internal_API+apiUrl_filter}?search=${options}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
};

function* getFilteredLevels({payload}) {
  try {
    const response = yield call(LevelsFilterRequest ,payload);
    if(response && response.status === 200){
      yield put(getLevelsListWithFilterSuccess(response.data));
    }else{
      yield put(getLevelsListWithFilterError(response));
    }
  } catch (error) {
    yield put(getLevelsListWithFilterError("Not Found"));
  }
}
const updateLevelRequest = async itemObj => {
  let itemId = itemObj.id;
	let body = {
    name: itemObj.name,
    status: itemObj.status
  }
	return await axios.put(`${internal_API+apiUrl_Levels}/${itemId}`,body,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}
function* updateLevel({ payload }) {
	try {
    const response = yield call(updateLevelRequest, payload);
	  if(response && (response.status === 200 || response.status === 201)){
		yield put(updateLevelSuccess(response.data));
	  }else{
		yield put(updateLevelError(response));
	  }
	} catch (error) {
	  yield put(updateLevelError(error));
	}
}

export function* watchGetLevels() {
  yield takeEvery(Levels_GET_LIST, getLevels);
}

export function* watchGetLevelDetail() {
  yield takeEvery(Level_GET_DETAILS, getLevelDetail);
}

export function* watchDeleteLevel() {
  yield takeEvery(Level_DELETE, deleteLevel);
}

export function* watchGetFilteredLevels() {
  yield takeEvery(Levels_GET_LIST_WITH_FILTER, getFilteredLevels);
}

export function* watchAddLevel() {
    yield takeEvery(Level_ADD_ITEM, addLevel);
}
export function* watchUpdateLevel() {
  yield takeEvery(Level_ITEM_UPDATE_DETAILS, updateLevel);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetLevels),
    fork(watchGetLevelDetail),
    fork(watchDeleteLevel),
    fork(watchGetFilteredLevels),
    fork(watchAddLevel),
    fork(watchUpdateLevel)

  ]);
}
