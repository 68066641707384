import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import storeScannerApp from "./store/reducer";
import storeItemDetail from "./storeDetail/reducer";
import requestedCarsList from "./requestedCarsList/reducer";
import ordersList from "./orders/reducer";
import missionsList from "./missions/reducer";
import scannedCars from "./scannedCars/reducer";
import matchedCars from "./matchedCars/reducer";
import levels from "./levels/reducer";
import usersMissionsList from "./usersMissions/reducer";
import configurations from "./configurations/reducer";
import statistics from "./statistics/reducer";
import users from "./users/reducer";
import companies from "./companies/reducer";
import lienholder from "./lienholder/reducer";

//combine reduces to one main object to save it in store.
const reducers = combineReducers({
  menu,
  settings,
  authUser,
  storeScannerApp,
  storeItemDetail,
  requestedCarsList,
  ordersList,
  missionsList,
  scannedCars,
  matchedCars,
  levels,
  usersMissionsList,
  configurations,
  statistics,
  users,
  companies,
  lienholder,
});

export default reducers;
