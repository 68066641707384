import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiUrl_usersMissions, internal_API ,apiUrl_filter} from "../../constants/defaultValues";

import {
  UsersMissions_GET_LIST,
  UsersMissions_GET_LIST_WITH_FILTER,
  MatchedCar_GET_DETAILS
} from "../actions";

import {
  getUsersMissionsListSuccess,
  getUsersMissionsListError,
  getUsersMissionsListWithFilterSuccess,
  getUsersMissionsListWithFilterError,
  getMatchedCarDetailSuccess,
  getMatchedCarDetailError
} from "./actions";
import axios from 'axios';

const UsersMissionsListRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_usersMissions}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
};

function* getUsersMissionsList() {
  try {
    const response = yield call(UsersMissionsListRequest);
    if(response && (response.status === 200 || response.status === 201)){
      yield put(getUsersMissionsListSuccess(response.data));
    }else{
      yield put(getUsersMissionsListError(response));
    }
  } catch (error) {
    yield put(getUsersMissionsListError(error));
  }
}

const UsersMissionsFilterRequest = async (payload) => {
  let options =  JSON.stringify({ "val" :payload.value, "key" : payload.column,"type":"UserMissions"});
  return await axios.get(`${internal_API+apiUrl_filter}?search=${options}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
};
function* getFilteredUsersMissions({payload}) {
  try {
    const response = yield call(UsersMissionsFilterRequest ,payload);
    if(response && response.status === 200){
      yield put(getUsersMissionsListWithFilterSuccess(response.data));
    }else{
      yield put(getUsersMissionsListWithFilterError(response));
    }
  } catch (error) {
    yield put(getUsersMissionsListWithFilterError("Not Found"));
  }
}

export function* watchGetUsersMissionsList() {
  yield takeEvery(UsersMissions_GET_LIST, getUsersMissionsList);
}
export function* watchGetFilteredUsersMissions() {
  yield takeEvery(UsersMissions_GET_LIST_WITH_FILTER, getFilteredUsersMissions);
}


export default function* rootSaga() {
	yield all([
		fork(watchGetUsersMissionsList),
		fork(watchGetFilteredUsersMissions)
	]);
}
