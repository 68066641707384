import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  apiUrl_users,
  internal_API,
  apiUrl_filter,
  apiUrl_logs,
} from "../../constants/defaultValues";

import {
  Users_GET_LIST,
  Users_GET_LIST_WITH_FILTER,
  User_LOGS_GET,
  Users_GET_ALL,
} from "../actions";

import {
  getUsersListSuccess,
  getUsersListError,
  getUsersListWithFilterSuccess,
  getUsersListWithFilterError,
  getUserLogsError,
  getUserLogsSuccess,
  getAllUsersSuccess,
  getAllUsersError,
} from "./actions";
import axios from "axios";

const UsersListRequest = async ({ payload }) => {
  const { skip, pageSize } = payload;
  return await axios
    .get(`${internal_API + apiUrl_users}?limit=${pageSize}&offset=${skip}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

const UserLogsRequest = async (userId) => {
  return await axios
    .get(`${internal_API + apiUrl_logs + userId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getUsersList(payload) {
  try {
    const response = yield call(UsersListRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] = payload.payload.currentPage;
      yield put(getUsersListSuccess(response.data));
    } else {
      yield put(getUsersListError(response));
    }
  } catch (error) {
    yield put(getUsersListError(error));
  }
}

function* getUserLogs({ payload }) {
  try {
    const response = yield call(UserLogsRequest, payload);

    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getUserLogsSuccess(response.data));
    } else {
      yield put(getUserLogsError(response));
    }
  } catch (error) {
    yield put(getUserLogsSuccess(error));
  }
}

const UsersFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "users",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
const getAllUsersRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_users}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* getFilteredUsers({ payload }) {
  try {
    const response = yield call(UsersFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getUsersListWithFilterSuccess(response.data));
    } else {
      yield put(getUsersListWithFilterError(response));
    }
  } catch (error) {
    yield put(getUsersListWithFilterError("Not Found"));
  }
}
function* getAllUsers() {
  try {
    const response = yield call(getAllUsersRequest);
    if (response && response.status === 200) {
      yield put(getAllUsersSuccess(response.data));
    } else {
      yield put(getAllUsersError(response));
    }
  } catch (error) {
    yield put(getAllUsersError("Not Found"));
  }
}
export function* watchGetUsersList() {
  yield takeEvery(Users_GET_LIST, getUsersList);
}
export function* watchGetFilteredUsers() {
  yield takeEvery(Users_GET_LIST_WITH_FILTER, getFilteredUsers);
}
export function* watchGetUserLogs() {
  yield takeEvery(User_LOGS_GET, getUserLogs);
}
export function* watchGetAllUsers() {
  yield takeEvery(Users_GET_ALL, getAllUsers);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetUsersList),
    fork(watchGetFilteredUsers),
    fork(watchGetUserLogs),
    fork(watchGetAllUsers),
  ]);
}
