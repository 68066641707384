import {
  Lienholder_GET_LIST,
  Lienholder_GET_LIST_SUCCESS,
  Lienholder_GET_LIST_ERROR,
  Lienholder_GET_DETAILS,
  Lienholder_GET_DETAILS_SUCCESS,
  Lienholder_GET_DETAILS_ERROR,
  Lienholder_DELETE,
  Lienholder_DELETE_SUCCESS,
  Lienholder_GET_LIST_WITH_FILTER,
  Lienholder_GET_LIST_WITH_FILTER_SUCCESS,
  Lienholder_GET_LIST_WITH_FILTER_ERROR,
  Lienholder_ITEM_UPDATE_DETAILS,
  Lienholder_ITEM_UPDATE_DETAILS_SUCCESS,
  Lienholder_ITEM_UPDATE_DETAILS_ERROR,
} from "../actions.js";

export const getLienholder = () => ({
  type: Lienholder_GET_LIST,
});

export const getLienholderListSuccess = (items) => ({
  type: Lienholder_GET_LIST_SUCCESS,
  payload: items,
});

export const getLienholderListError = (error) => ({
  type: Lienholder_GET_LIST_ERROR,
  payload: error,
});
export const getLienholderDetail = (itemId) => ({
  type: Lienholder_GET_DETAILS,
  payload: itemId,
});

export const getLienholderDetailSuccess = (items) => ({
  type: Lienholder_GET_DETAILS_SUCCESS,
  payload: items,
});

export const getLienholderDetailError = (error) => ({
  type: Lienholder_GET_DETAILS_ERROR,
  payload: error,
});

export const getLienholderListWithFilter = (column, value) => ({
  type: Lienholder_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getLienholderListWithFilterSuccess = (items) => ({
  type: Lienholder_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});

export const getLienholderListWithFilterError = (items) => ({
  type: Lienholder_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});

export const updateLienholder = (item) => ({
  type: Lienholder_ITEM_UPDATE_DETAILS,
  payload: item,
});

export const updateLienholderuccess = (item) => ({
  type: Lienholder_ITEM_UPDATE_DETAILS_SUCCESS,
  payload: item,
});

export const updateLienholderError = (item) => ({
  type: Lienholder_ITEM_UPDATE_DETAILS_ERROR,
  payload: item,
});
export const deleteLienholder = (itemId, item) => ({
  type: Lienholder_DELETE,
  payload: { itemId, item },
});
export const deleteLienholderuccess = (item) => ({
  type: Lienholder_DELETE_SUCCESS,
  payload: item,
});
