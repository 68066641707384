import {
    UsersMissions_GET_LIST,
    UsersMissions_GET_LIST_SUCCESS,
    UsersMissions_GET_LIST_ERROR,
    UsersMissions_GET_LIST_WITH_FILTER,
    UsersMissions_GET_LIST_WITH_FILTER_SUCCESS,
    UsersMissions_GET_LIST_WITH_FILTER_ERROR
} from "../actions.js"


export const getUsersMissions = () => ({
    type: UsersMissions_GET_LIST
});

export const getUsersMissionsListSuccess = (items) => ({
    type: UsersMissions_GET_LIST_SUCCESS,
    payload: items
});

export const getUsersMissionsListError = (error) => ({
    type: UsersMissions_GET_LIST_ERROR,
    payload: error
});

export const getUsersMissionsListWithFilter = (column, value) => ({
    type: UsersMissions_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getUsersMissionsListWithFilterSuccess = (items) => ({
    type: UsersMissions_GET_LIST_WITH_FILTER_SUCCESS,
    payload: items
});
export const getUsersMissionsListWithFilterError = (items) => ({
    type: UsersMissions_GET_LIST_WITH_FILTER_ERROR,
    payload: items
});