import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { internal_API } from "../../constants/defaultValues";

import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordEmailError,
  resetPasswordEmailSuccess,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";
import axios from "axios";
const jwt = require("jsonwebtoken");

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  var body = {
    email: email,
    password: password,
    role: "admin",
  };
  try {
    const response = await axios.post(internal_API + "api/auth", body, options);
    let decodeToken = jwt.decode(response.data.token);
    const user_id = (decodeToken || {}).user.id;
    let user_role = (decodeToken || {}).user.role;
    const userProfileRes = await axios.get(internal_API + "api/userProfile", {
      headers: {
        "x-auth-token": response.data.token,
      },
    });
    if (!/^\admin$/.test(user_role)) {
      return { status: 401, message: "Unauthorized Action " };
    }
    const user = {
      uid: user_id,
      role: user_role,
      userProfile: {
        id: userProfileRes.data.id,
        userId: userProfileRes.data.userId,
        name: userProfileRes.data.name,
        avatar: userProfileRes.data.avatar,
      },
    };
    response["user"] = user;
    return response;
  } catch (error) {
    return error;
  }
};
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser && loginUser.status === 200) {
      localStorage.setItem("uid", loginUser.user.uid);
      localStorage.setItem("jwtToken", loginUser.data.token);
      localStorage.setItem(
        "userProfile",
        JSON.stringify(loginUser.user.userProfile)
      );
      localStorage.setItem("email", email);
      yield put(loginUserSuccess(loginUser.user));
      window.location.replace("/");
    } else {
      yield put(loginUserError(loginUser.response.data.errors[0].msg));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // await auth.createUserWithEmailAndPassword(email, password)
  //     .then(authUser => authUser)
  //     .catch(error => error);

  function* registerWithEmailPassword({ payload }) {
    // const { email, password } = payload.user;
    // const { history } = payload
    // try {
    //     const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
    //     if (!registerUser.message) {
    //         localStorage.setItem('uid', registerUser.user.uid);
    //         yield put(registerUserSuccess(registerUser));
    //         history.push('/')
    //     } else {
    //         yield put(registerUserError(registerUser.message));
    //     }
    // } catch (error) {
    //     yield put(registerUserError(error));
    // }
  };

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  localStorage.setItem("uid", null);
  localStorage.setItem("jwtToken", null);
  localStorage.setItem("userProfile", null);
  localStorage.setItem("email", null);
  history.push("/user/login");
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem("uid");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("email");
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await axios
    .get(
      `${internal_API}api/auth/forgetPassword?email=${encodeURIComponent(
        email
      )}`
    )
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus && forgotPasswordStatus.status === 200) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword, password) => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken"),
      "Content-Type": "application/json",
    },
  };
  var body = {
    email: localStorage.getItem("email"),
    password: password,
    newPassword: newPassword,
    resetPasswordCode: resetPasswordCode,
  };
  return await axios
    .put(`${internal_API}api/auth/reset`, body, options)
    .then((user) => "success")
    .catch((error) => {
      return error;
    });
};

const resetPasswordEmailAsync = async (payload) => {
  let email = localStorage.getItem("email");
  return await axios
    .get(`${internal_API}api/auth/reset-password/verify?email=${email}`)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode, password, history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword,
      password
    );
    if (resetPasswordStatus === "success") {
      localStorage.setItem("uid", null);
      localStorage.setItem("jwtToken", null);
      localStorage.setItem("userProfile", null);
      localStorage.setItem("email", null);
      history.push("/user/login");
      localStorage.removeItem("uid");
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("email");
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

function* resetPasswordEmail(payload) {
  try {
    const resetPasswordStatus = yield call(resetPasswordEmailAsync, payload);
    if (!resetPasswordStatus) {
      yield put(resetPasswordEmailSuccess("success"));
    } else {
      yield put(resetPasswordEmailError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordEmailError(error));
  }
}
export function* watchResetPasswordEmail() {
  yield takeEvery(RESET_PASSWORD_EMAIL, resetPasswordEmail);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchResetPasswordEmail),
  ]);
}
