/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";
export const internal_API = "https://ap.dev.repocoin.io/";
// export const internal_API = "http://localhost:8080/";
// export const driver_API = "http://localhost:9000/";
export const driver_API = "https://driver.dev.repocoin.io/";
export const apiUrl_users = "api/users";
export const apiUrl_filter = "api/filter";
export const apiUrl_store = "api/store";
export const apiUrl_upload = "api/upload";
export const apiUrl_requestedCars = "api/requestedCars";
export const apiUrl_order = "api/order";
export const apiUrl_orders = "api/order/all";
export const apiUrl_orderInfo = "api/order/info";
export const apiUrl_missions = "api/mission";
export const apiUrl_scannedCars = "api/scan";
export const apiUrl_matchedCars = "api/matchedCars";
export const apiUrl_Levels = "api/level";
export const apiUrl_LevelInfo = "api/level/info";
export const apiUrl_levelsWithMissions = "api/level/missions";
export const apiUrl_usersMissions = "api/userMission";
export const apiUrl_configurations = "api/configurations";
export const apiUrl_handovers = "api/handover";
export const apiUrl_fee = "api/fee";
export const apiUrl_statistics = "api/statistic";
export const apiUrl_track = "api/track";
export const apiUrl_publicKey = "api/auth/publicKey";
export const apiUrl_logs = "api/users/logs/";
export const apiUrl_companies = "api/company";
export const apiUrl_vehicles = "api/company/vehicle";
export const apiUrl_storages = "api/company/storage";
export const apiUrl_drivers = "api/company/driver";
export const apiUrl_Lienholder = "api/lienholder";
export const pageSize = 10;
/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const statesList = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  " Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
export const makeList = [
  "Alfa Romeo",
  "BMW",
  "Bentley",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Daewoo",
  "Dodge",
  "Ferrari",
  "Fiat",
  "Ford",
  "Holden",
  "Honda",
  "Hyundai",
  "Infiniti",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "Kia",
  "Lexus",
  "Lincoln",
  "Mazda",
  "Mazda",
  "Mercedes-Benz",
  "Mitsubishi",
  "Nissan",
  "Peugeot",
  "Porsche",
  "Subaru",
  "Suzuki",
  "Toyota",
  "Volkswagen",
];
export const themeColorStorageKey = "__theme_color";
export const isMultiColorActive = true;
export const defaultColor = "light.blue";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
