import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiUrl_order, internal_API ,apiUrl_filter, apiUrl_missions ,apiUrl_levelsWithMissions} from "../../constants/defaultValues";

import {
    MISSIONS_GET_LIST,
    MISSIONS_GET_LIST_SUCCESS,
    MISSIONS_GET_LIST_ERROR,
    MISSION_GET_DETAILS,
    MISSION_GET_DETAILS_SUCCESS,
    MISSION_GET_DETAILS_ERROR,
    MISSION_DELETE,
    MISSION_DELETE_SUCCESS,
    MISSIONS_GET_LIST_WITH_FILTER,
    MISSIONS_GET_LIST_WITH_FILTER_SUCCESS,
    MISSIONS_GET_LIST_WITH_FILTER_ERROR,
    MISSION_ADD_ITEM,
    MISSION_ADD_ITEM_SUCCESS,
    MISSION_ADD_ITEM_ERROR,
    MISSION_ITEM_UPDATE_DETAILS,
    GET_AVAILABLE_LEVELS
} from "../actions.js"

import {
    getMissionsListSuccess,
    getMissionsListError,
    getMissionsListWithFilterSuccess,
    getMissionsListWithFilterError,
    getMissionDetailError,
    getMissionDetailSuccess,
    addMissionItem,
    addMissionItemSuccess,
    addMissionItemError,
    updateMissionSuccess,
    updateMissionError,
    getAvailableLevelsSuccess,
    getAvailableLevelsError,
    deleteMissionSuccess
  } from "./actions";

  import axios from 'axios';


const missionsRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_missions}`,{
          headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
          }
      })
      .then(response => response)
      .catch(error => error);
};
  
function* getMissions() {
  try {
      const response = yield call(missionsRequest);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(getMissionsListSuccess(response.data));
      }else{
        yield put(getMissionsListError(response));
      }
  } catch (error) {
        yield put(getMissionsListError(error));
  }
}

const getMissionDetailRequest = async item => {
  let itemId = item.payload;
	return await axios.get(`${internal_API+apiUrl_missions}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* getMissionDetail(payload) {
	try {
    const response = yield call(getMissionDetailRequest, payload);
		if(response && (response.status === 200 || response.status == 201)){
			yield put(getMissionDetailSuccess(response.data));
		}else{
			yield put(getMissionDetailError(response));
		}
	} catch (error) {
		yield put(getMissionDetailError(error));
	}
}
const addMissionRequest = async item => {
    const options = {
      headers: {
        "x-auth-token": localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json'
        }
    };
    var body = {
      title: item.title,
      points: item.points,
      time: item.time || "-",
      color: item.missionColor,
      status: item.status,
      type: item.type,
      levelId: item.levelId
    };
    return await axios.post(`${internal_API+apiUrl_missions}`,body,options)
  .then(async (response) => {
    if(response && (response.status === 200 || response.status === 201)){
      return await axios.get(`${internal_API+apiUrl_missions}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    }else{
      return response
    }
  })
  .catch(error => error);
  };
  
  function* addMission({ payload }) {
    try {
      const response = yield call(addMissionRequest, payload);
      if(response && (response.status === 200 || response.status === 201)){
        yield all([
          put(addMissionItemSuccess(response.data)),
          put({ type: 'GET_AVAILABLE_LEVELS'})
        ])
        }else{
        yield put(addMissionItemError(response));
      }
    } catch (error) {
      yield put(addMissionItemError(error));
    }
  }


const deleteMissionRequest = async (itemId, item) => {
	return await axios.delete(`${internal_API+apiUrl_missions}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* deleteMission({ payload }) {
	try {
    const { itemId, item } = payload;
		const response = yield call(deleteMissionRequest, itemId, item);
		if(response && response.status === 200 || response.status === 201){
			yield put(deleteMissionSuccess(response));
		  }else{
			yield put(getMissionDetailError(response));
		  }
	} catch (error) {
		yield put(getMissionDetailError(error));
	}
}

const getAvailableLevelsRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_levelsWithMissions}`)
      .then(response => {
        let availableLevels = [];
        response.data.map(level =>{
          if(level.Missions && level.Missions.length <= 2 ){
            availableLevels.push({key:level.id,value:level.name});
          }
        })
          return {status : 200 , data:availableLevels}
      })
      .catch(error => error);
};
function* getAvailableLevels({ payload }) {
  try {
    const response = yield call(getAvailableLevelsRequest, payload);
    if(response && (response.status === 200 || response.status === 201)){
      yield put(getAvailableLevelsSuccess(response.data));
    }else{
      yield put(getAvailableLevelsError(response));
    }
  } catch (error) {
    yield put(getAvailableLevelsError(error));
  }
}
const missionsFilterRequest = async (payload) => {
  let options =  JSON.stringify({ "val" :payload.value, "key" : payload.column,"type":"missions"});
  return await axios.get(`${internal_API+apiUrl_filter}?search=${options}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
};

function* getFilteredMissions({payload}) {
  try {
    const response = yield call(missionsFilterRequest ,payload);
    if(response && response.status === 200){
      yield put(getMissionsListWithFilterSuccess(response.data));
    }else{
      yield put(getMissionsListWithFilterError(response));
    }
  } catch (error) {
    yield put(getMissionsListWithFilterError("Not Found"));
  }
}
const updateMissionRequest = async itemObj => {
	let itemId = itemObj.id;
	let body = {
    title: itemObj.title,
    points: itemObj.points,
    time: itemObj.time || "-",
    color: itemObj.color,
    status: itemObj.status,
    type: itemObj.type,
    levelId: itemObj.levelId
  }
	return await axios.put(`${internal_API+apiUrl_missions}/${itemId}`,body,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}
function* updateMission({ payload }) {
	try {
    const response = yield call(updateMissionRequest, payload);
	  if(response && response.status === 200 || response.status === 201){
		yield put(updateMissionSuccess(response.data));
	  }else{
		yield put(updateMissionError(response));
	  }
	} catch (error) {
	  yield put(updateMissionError(error));
	}
}

export function* watchGetMissions() {
  yield takeEvery(MISSIONS_GET_LIST, getMissions);
}

export function* watchGetMissionDetail() {
  yield takeEvery(MISSION_GET_DETAILS, getMissionDetail);
}

export function* watchDeleteMission() {
  yield takeEvery(MISSION_DELETE, deleteMission);
}

export function* watchGetFilteredMissions() {
  yield takeEvery(MISSIONS_GET_LIST_WITH_FILTER, getFilteredMissions);
}

export function* watchAddMission() {
    yield takeEvery(MISSION_ADD_ITEM, addMission);
}

export function* watchUpdateMission() {
  yield takeEvery(MISSION_ITEM_UPDATE_DETAILS, updateMission);
}

export function* watchAvailableLevels() {
  yield takeEvery(GET_AVAILABLE_LEVELS, getAvailableLevels);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMissions),
    fork(watchGetMissionDetail),
    fork(watchDeleteMission),
    fork(watchGetFilteredMissions),
    fork(watchAddMission),
    fork(watchUpdateMission),
    fork(watchAvailableLevels)

  ]);
}
