import {
    ORDERS_GET_LIST,
    ORDERS_GET_LIST_SUCCESS,
    ORDERS_GET_LIST_ERROR,
    ORDER_GET_DETAILS,
    ORDER_GET_DETAILS_SUCCESS,
    ORDER_GET_DETAILS_ERROR,
    ORDER_DELETE,
    ORDER_DELETE_SUCCESS,
    ORDERS_GET_LIST_WITH_FILTER,
    ORDERS_GET_LIST_WITH_FILTER_SUCCESS,
    ORDERS_GET_LIST_WITH_FILTER_ERROR,
    ORDER_UPDATE,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_ERROR,
    ALPHA_GET,
    ALPHA_GET_SUCCESS,
    ALPHA_GET_ERROR,
    ALPHA_UPDATE,
    ALPHA_UPDATE_SUCCESS,
    ALPHA_UPDATE_ERROR,
    ANDROID_GET,
    ANDROID_GET_SUCCESS,
    ANDROID_GET_ERROR,
} from "../actions.js"

export const getOrdersList = () => ({
    type: ORDERS_GET_LIST
});

export const getOrdersListSuccess = (items) => ({
    type: ORDERS_GET_LIST_SUCCESS,
    payload: items
});

export const getOrdersListError = (error) => ({
    type: ORDERS_GET_LIST_ERROR,
    payload: error
});

export const getAlpha = () => ({
    type: ALPHA_GET
});

export const getAlphaSuccess = (items) => ({
    type: ALPHA_GET_SUCCESS,
    payload: items
});

export const getAlphaError = (error) => ({
    type: ALPHA_GET_ERROR,
    payload: error
});

export const updateAlpha = (item) => ({
    type: ALPHA_UPDATE,
    payload: item
});

export const updateAlphaSuccess = (items) => ({
    type: ALPHA_UPDATE_SUCCESS,
    payload: items
});

export const updateAlphaError = (error) => ({
    type: ALPHA_UPDATE_ERROR,
    payload: error
});

export const getAndroid = () => ({
    type: ANDROID_GET
});

export const getAndroidSuccess = (items) => ({
    type: ANDROID_GET_SUCCESS,
    payload: items
});

export const getAndroidError = (error) => ({
    type: ANDROID_GET_ERROR,
    payload: error
});


export const getOrderDetail = (itemId) => ({
    type: ORDER_GET_DETAILS,
    payload: itemId
});

export const getOrderDetailSuccess = (items) => ({
    type: ORDER_GET_DETAILS_SUCCESS,
    payload: items
});

export const getOrderDetailError = (error) => ({
    type: ORDER_GET_DETAILS_ERROR,
    payload: error
});

export const deleteOrder = (itemId,item) => ({
    type: ORDER_DELETE,
    payload: {itemId,item}
});

export const deleteOrderSuccess = (item) => ({
    type: ORDER_DELETE_SUCCESS,
    payload: item
});

export const getOrdersListWithFilter = (column, value) => ({
    type: ORDERS_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getOrdersListWithFilterSuccess = (items) => ({
    type: ORDERS_GET_LIST_WITH_FILTER_SUCCESS,
    payload: items
});

export const getOrdersListWithFilterError = (items) => ({
    type: ORDERS_GET_LIST_WITH_FILTER_ERROR,
    payload: items
});
export const updateOrder = (item) => ({
    type: ORDER_UPDATE,
    payload: item
});

export const updateOrderSuccess = (item) => ({
    type: ORDER_UPDATE_SUCCESS,
    payload: item
});

export const updateOrderError = (item) => ({
    type: ORDER_UPDATE_ERROR,
    payload: item
});