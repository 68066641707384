import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  internal_API,
  apiUrl_filter,
  apiUrl_Lienholder,
} from "../../constants/defaultValues";

import {
  Lienholder_GET_LIST,
  Lienholder_GET_DETAILS,
  Lienholder_DELETE,
  Lienholder_GET_LIST_WITH_FILTER,
  Lienholder_ITEM_UPDATE_DETAILS,
} from "../actions.js";

import {
  getLienholderListSuccess,
  getLienholderListError,
  getLienholderListWithFilterSuccess,
  getLienholderListWithFilterError,
  getLienholderDetailError,
  getLienholderDetailSuccess,
  updateLienholderuccess,
  updateLienholderError,
  deleteLienholderuccess,
} from "./actions";

import axios from "axios";

const LienholderRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_Lienholder}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getLienholder() {
  try {
    const response = yield call(LienholderRequest);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getLienholderListSuccess(response.data));
    } else {
      yield put(getLienholderListError(response));
    }
  } catch (error) {
    yield put(getLienholderListError(error));
  }
}

const getLienholderDetailRequest = async (item) => {
  let itemId = item.payload;
  return await axios
    .get(`${internal_API + apiUrl_Lienholder}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getLienholderDetail(payload) {
  try {
    const response = yield call(getLienholderDetailRequest, payload);
    if (response && (response.status === 200 || response.status == 201)) {
      yield put(getLienholderDetailSuccess(response.data));
    } else {
      yield put(getLienholderDetailError(response));
    }
  } catch (error) {
    yield put(getLienholderDetailError(error));
  }
}
const addLienholderRequest = async (item) => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken"),
      "Content-Type": "application/json",
    },
  };
  var body = {
    name: item.name,
    status: item.status,
    locked: false,
  };
  return await axios
    .post(`${internal_API + apiUrl_Lienholder}`, body, options)
    .then(async (response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        return await axios.get(`${internal_API + apiUrl_Lienholder}`, {
          headers: {
            "x-auth-token": localStorage.getItem("jwtToken"),
          },
        });
      } else {
        return response;
      }
    })
    .catch((error) => error);
};

const deleteLienholderRequest = async (itemId, item) => {
  return await axios
    .delete(`${internal_API + apiUrl_Lienholder}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* deleteLienholder({ payload }) {
  try {
    const { itemId, item } = payload;
    const response = yield call(deleteLienholderRequest, itemId, item);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getLienholderListSuccess(response));
    } else {
      yield put(getLienholderDetailError(response));
    }
  } catch (error) {
    yield put(getLienholderDetailError(error));
  }
}

const LienholderFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "Lienholder",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getFilteredLienholder({ payload }) {
  try {
    const response = yield call(LienholderFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getLienholderListWithFilterSuccess(response.data));
    } else {
      yield put(getLienholderListWithFilterError(response));
    }
  } catch (error) {
    yield put(getLienholderListWithFilterError("Not Found"));
  }
}
const updateLienholderRequest = async (itemObj) => {
  let itemId = itemObj.id;
  let body = {
    name: itemObj.name,
    status: itemObj.status,
  };
  return await axios
    .put(`${internal_API + apiUrl_Lienholder}/${itemId}`, body, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* updateLienholder({ payload }) {
  try {
    const response = yield call(updateLienholderRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(updateLienholderuccess(response.data));
    } else {
      yield put(updateLienholderError(response));
    }
  } catch (error) {
    yield put(updateLienholderError(error));
  }
}

export function* watchGetLienholder() {
  yield takeEvery(Lienholder_GET_LIST, getLienholder);
}

export function* watchGetLienholderDetail() {
  yield takeEvery(Lienholder_GET_DETAILS, getLienholderDetail);
}

export function* watchDeleteLienholder() {
  yield takeEvery(Lienholder_DELETE, deleteLienholder);
}

export function* watchGetFilteredLienholder() {
  yield takeEvery(Lienholder_GET_LIST_WITH_FILTER, getFilteredLienholder);
}

export function* watchUpdateLienholder() {
  yield takeEvery(Lienholder_ITEM_UPDATE_DETAILS, updateLienholder);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetLienholder),
    fork(watchGetLienholderDetail),
    fork(watchDeleteLienholder),
    fork(watchGetFilteredLienholder),
    fork(watchUpdateLienholder),
  ]);
}
