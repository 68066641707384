import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  apiUrl_configurations,
  internal_API,
  apiUrl_filter,
  apiUrl_publicKey,
  driver_API,
} from "../../constants/defaultValues";

import {
  Configurations_GET_LIST,
  Configurations_UPDATE,
  Config_Driver,
  Config_Driver_UPDATE,
} from "../actions";

import {
  getConfigurationsListSuccess,
  getConfigurationsListError,
  updateConfigurationsError,
  updateConfigurationsSuccess,
  getDriverConfigError,
  getDriverConfigSuccess,
  updateDriverConfigSuccess,
  updateDriverConfigError,
} from "./actions";
import axios from "axios";

const ConfigurationsListRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_configurations}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getConfigurationsList() {
  try {
    const response = yield call(ConfigurationsListRequest);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getConfigurationsListSuccess(response.data));
    } else {
      yield put(getConfigurationsListError(response));
    }
  } catch (error) {
    yield put(getConfigurationsListError(error));
  }
}

const updateConfigurationsRequest = async (obj) => {
  let itemId = obj.id;
  let body = obj.updatedItem;
  return await axios
    .put(`${internal_API + apiUrl_configurations}/${itemId}`, body, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
const getConfigDriverRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_publicKey}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then(async (response) => {
      return await axios
        .get(`${driver_API + apiUrl_configurations}`, {
          headers: {
            authorization: response.data,
          },
        })
        .then((result) => {
          return result;
        })
        .catch((error) => error);
    })
    .catch((error) => error);
};
const updateConfigDriverRequest = async (obj) => {
  let itemId = obj.id;
  let body = obj.updatedItem;
  return await axios
    .get(`${internal_API + apiUrl_publicKey}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then(async (response) => {
      return await axios
        .put(`${driver_API + apiUrl_configurations}/${itemId}`, body, {
          headers: {
            authorization: response.data,
          },
        })
        .then((result) => {
          return result;
        })
        .catch((error) => error);
    })
    .catch((error) => error);
};

function* updateConfigurations({ payload }) {
  try {
    const response = yield call(updateConfigurationsRequest, payload);
    if ((response && response.status === 200) || response.status === 201) {
      yield put(updateConfigurationsSuccess(response.data));
    } else {
      yield put(updateConfigurationsError(response));
    }
  } catch (error) {
    yield put(updateConfigurationsError(error));
  }
}

function* getConfigDriver({ payload }) {
  try {
    const response = yield call(getConfigDriverRequest, payload);
    if ((response && response.status === 200) || response.status === 201) {
      yield put(getDriverConfigSuccess(response.data));
    } else {
      yield put(getDriverConfigError(response));
    }
  } catch (error) {
    yield put(getDriverConfigError(error));
  }
}
function* updateConfigDriver({ payload }) {
  try {
    const response = yield call(updateConfigDriverRequest, payload);
    if ((response && response.status === 200) || response.status === 201) {
      yield put(updateDriverConfigSuccess(response.data));
    } else {
      yield put(updateDriverConfigError(response));
    }
  } catch (error) {
    yield put(updateDriverConfigError(error));
  }
}

export function* watchGetConfigurationsList() {
  yield takeEvery(Configurations_GET_LIST, getConfigurationsList);
}
export function* watchUpdateConfigurations() {
  yield takeEvery(Configurations_UPDATE, updateConfigurations);
}
export function* watchGetConfigDriver() {
  yield takeEvery(Config_Driver, getConfigDriver);
}
export function* watchUpdateConfigDriver() {
  yield takeEvery(Config_Driver_UPDATE, updateConfigDriver);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetConfigurationsList),
    fork(watchUpdateConfigurations),
    fork(watchGetConfigDriver),
    fork(watchUpdateConfigDriver),
  ]);
}
