import {
    MISSIONS_GET_LIST,
    MISSIONS_GET_LIST_SUCCESS,
    MISSIONS_GET_LIST_ERROR,
    MISSION_GET_DETAILS,
    MISSION_GET_DETAILS_SUCCESS,
    MISSION_GET_DETAILS_ERROR,
    MISSION_DELETE,
    MISSION_DELETE_SUCCESS,
    MISSIONS_GET_LIST_WITH_FILTER,
    MISSIONS_GET_LIST_WITH_FILTER_SUCCESS,
    MISSIONS_GET_LIST_WITH_FILTER_ERROR,
    MISSION_ADD_ITEM,
    MISSION_ADD_ITEM_SUCCESS,
    MISSION_ADD_ITEM_ERROR,
    MISSION_ITEM_UPDATE_DETAILS,
	MISSION_ITEM_UPDATE_DETAILS_SUCCESS,
    MISSION_ITEM_UPDATE_DETAILS_ERROR,
    GET_AVAILABLE_LEVELS,
	GET_AVAILABLE_LEVELS_SUCCESS,
	GET_AVAILABLE_LEVELS_ERROR
} from "../actions.js"

export const getMissionsList = () => ({
    type: MISSIONS_GET_LIST
});

export const getMissionsListSuccess = (items) => ({
    type: MISSIONS_GET_LIST_SUCCESS,
    payload: items
});

export const getMissionsListError = (error) => ({
    type: MISSIONS_GET_LIST_ERROR,
    payload: error
});
export const getMissionDetail = (itemId) => ({
    type: MISSION_GET_DETAILS,
    payload: itemId
});

export const getMissionDetailSuccess = (items) => ({
    type: MISSION_GET_DETAILS_SUCCESS,
    payload: items
});

export const getMissionDetailError = (error) => ({
    type: MISSION_GET_DETAILS_ERROR,
    payload: error
});

export const deleteMission = (itemId,item) => ({
    type: MISSION_DELETE,
    payload: {itemId,item}
});

export const deleteMissionSuccess = (item) => ({
    type: MISSION_DELETE_SUCCESS,
    payload: item
});

export const getMissionsListWithFilter = (column, value) => ({
    type: MISSIONS_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getMissionsListWithFilterSuccess = (items) => ({
    type: MISSIONS_GET_LIST_WITH_FILTER_SUCCESS,
    payload: items
});

export const getMissionsListWithFilterError = (items) => ({
    type: MISSIONS_GET_LIST_WITH_FILTER_ERROR,
    payload: items
});
export const addMissionItem = (item) => ({
    type: MISSION_ADD_ITEM,
    payload: item
});
export const addMissionItemSuccess = (items) => ({
    type: MISSION_ADD_ITEM_SUCCESS,
    payload: items
});

export const addMissionItemError = (error) => ({
    type: MISSION_ADD_ITEM_ERROR,
    payload: error
});

export const updateMission = (item) => ({
    type: MISSION_ITEM_UPDATE_DETAILS,
    payload: item
});

export const updateMissionSuccess = (item) => ({
    type: MISSION_ITEM_UPDATE_DETAILS_SUCCESS,
    payload: item
});

export const updateMissionError = (item) => ({
    type: MISSION_ITEM_UPDATE_DETAILS_ERROR,
    payload: item
});

export const getAvailableLevels = (item) => ({
    type: GET_AVAILABLE_LEVELS,
    payload: item
});

export const getAvailableLevelsSuccess = (item) => ({
    type: GET_AVAILABLE_LEVELS_SUCCESS,
    payload: item
});

export const getAvailableLevelsError = (item) => ({
    type: GET_AVAILABLE_LEVELS_ERROR,
    payload: item
});