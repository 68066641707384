import {
  ReqCARS_GET_LIST,
  ReqCARS_GET_LIST_SUCCESS,
  ReqCARS_GET_LIST_ERROR,
  ReqCARS_GET_LIST_WITH_FILTER,
  ReqCARS_ADD_ITEM,
  ReqCARS_ADD_ITEM_SUCCESS,
  ReqCARS_ADD_ITEM_ERROR,
  ReqCARS_GET_LIST_WITH_FILTER_SUCCESS,
  ReqCARS_GET_LIST_WITH_FILTER_ERROR,
  ReqCAR_GET_DETAIL,
  ReqCAR_GET_DETAIL_SUCCESS,
  ReqCAR_GET_DETAIL_ERROR,
  ReqCAR_ITEM_UPDATE_DETAILS,
  ReqCAR_ITEM_UPDATE_DETAILS_SUCCESS,
  ReqCAR_ITEM_UPDATE_DETAILS_ERROR,
  ReqCAR_ITEM_DELETE,
  ReqCAR_ITEM_DELETE_SUCCESS,
  ReqCARS_GET_LIST_ALL,
  ReqCARS_GET_LIST_SUCCESS_ALL,
  ReqCARS_GET_LIST_ERROR_ALL,
} from "../actions.js";

export const getReqCarsList = (payload) => ({
  type: ReqCARS_GET_LIST,
  payload: payload,
});

export const getReqCarsListSuccess = (items) => ({
  type: ReqCARS_GET_LIST_SUCCESS,
  payload: items,
});

export const getReqCarsListError = (error) => ({
  type: ReqCARS_GET_LIST_ERROR,
  payload: error,
});
export const addReqCarItem = (item) => ({
  type: ReqCARS_ADD_ITEM,
  payload: item,
});
export const addReqCarItemSuccess = (items) => ({
  type: ReqCARS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addReqCarItemError = (error) => ({
  type: ReqCARS_ADD_ITEM_ERROR,
  payload: error,
});

export const getReqCarsListWithFilter = (column, value) => ({
  type: ReqCARS_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getReqCarsListWithFilterSuccess = (items) => ({
  type: ReqCARS_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getReqCarsListWithFilterError = (items) => ({
  type: ReqCARS_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const getReqCarDetail = (itemId) => ({
  type: ReqCAR_GET_DETAIL,
  payload: itemId,
});

export const getReqCarDetailSuccess = (items) => ({
  type: ReqCAR_GET_DETAIL_SUCCESS,
  payload: items,
});

export const getReqCarDetailError = (error) => ({
  type: ReqCAR_GET_DETAIL_ERROR,
  payload: error,
});
export const updateRequestedCar = (item) => ({
  type: ReqCAR_ITEM_UPDATE_DETAILS,
  payload: item,
});
export const updateRequestedCarSuccess = (item) => ({
  type: ReqCAR_ITEM_UPDATE_DETAILS_SUCCESS,
  payload: item,
});
export const updateRequestedCarError = (item) => ({
  type: ReqCAR_ITEM_UPDATE_DETAILS_ERROR,
  payload: item,
});

export const deleteRequestedCar = (itemId, item) => ({
  type: ReqCAR_ITEM_DELETE,
  payload: { itemId, item },
});
export const deleteRequestedCarSuccess = (item) => ({
  type: ReqCAR_ITEM_DELETE_SUCCESS,
  payload: item,
});

export const getAllReqCars = () => ({
  type: ReqCARS_GET_LIST_ALL,
});

export const getAllReqCarsSuccess = (items) => ({
  type: ReqCARS_GET_LIST_SUCCESS_ALL,
  payload: items,
});

export const getAllReqCarsError = (error) => ({
  type: ReqCARS_GET_LIST_ERROR_ALL,
  payload: error,
});
