import {
  ScannedCars_GET_LIST,
  ScannedCars_GET_LIST_SUCCESS,
  ScannedCars_GET_LIST_ERROR,
  ScannedCars_GET_LIST_WITH_FILTER,
  ScannedCars_GET_LIST_WITH_FILTER_SUCCESS,
  ScannedCars_GET_LIST_WITH_FILTER_ERROR,
  ScannedCar_GET_DETAILS,
  ScannedCar_GET_DETAILS_SUCCESS,
  ScannedCar_GET_DETAILS_ERROR,
  ScannedCar_GET_ALL,
  ScannedCar_GET_ALL_SUCCESS,
  ScannedCar_GET_ALL_ERROR,
} from "../actions.js";

export const getScannedCarsList = (payload) => ({
  type: ScannedCars_GET_LIST,
  payload: payload,
});

export const getScannedCarsListSuccess = (items) => ({
  type: ScannedCars_GET_LIST_SUCCESS,
  payload: items,
});

export const getScannedCarsListError = (error) => ({
  type: ScannedCars_GET_LIST_ERROR,
  payload: error,
});

export const getScannedCarsListWithFilter = (column, value) => ({
  type: ScannedCars_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getScannedCarsListWithFilterSuccess = (items) => ({
  type: ScannedCars_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getScannedCarsListWithFilterError = (items) => ({
  type: ScannedCars_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const getScannedCarDetail = (itemId) => ({
  type: ScannedCar_GET_DETAILS,
  payload: itemId,
});

export const getScannedCarDetailSuccess = (items) => ({
  type: ScannedCar_GET_DETAILS_SUCCESS,
  payload: items,
});

export const getScannedCarDetailError = (error) => ({
  type: ScannedCar_GET_DETAILS_ERROR,
  payload: error,
});

export const getAllScannedCars = (payload) => ({
  type: ScannedCar_GET_ALL,
  payload: payload,
});

export const getAllScannedCarsSuccess = (items) => ({
  type: ScannedCar_GET_ALL_SUCCESS,
  payload: items,
});

export const getAllScannedCarsError = (error) => ({
  type: ScannedCar_GET_ALL_ERROR,
  payload: error,
});
