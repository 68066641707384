import {
  Pending_GET_LIST,
  Pending_GET_LIST_SUCCESS,
  Pending_GET_LIST_ERROR,
  Pending_GET_LIST_WITH_FILTER,
  Pending_GET_LIST_WITH_FILTER_SUCCESS,
  Pending_GET_LIST_WITH_FILTER_ERROR,
  Approved_GET_LIST,
  Approved_GET_LIST_SUCCESS,
  Approved_GET_LIST_ERROR,
  Approved_GET_LIST_WITH_FILTER,
  Approved_GET_LIST_WITH_FILTER_SUCCESS,
  Approved_GET_LIST_WITH_FILTER_ERROR,
  Rejected_GET_LIST,
  Rejected_GET_LIST_SUCCESS,
  Rejected_GET_LIST_ERROR,
  Rejected_GET_LIST_WITH_FILTER,
  Rejected_GET_LIST_WITH_FILTER_SUCCESS,
  Rejected_GET_LIST_WITH_FILTER_ERROR,
  Company_UPDATE,
  Company_UPDATE_SUCCESS,
  Company_UPDATE_ERROR,
  Get_Company_Detail,
  Get_Company_Detail_SUCCESS,
  Get_Company_Detail_ERROR,
  Get_Vehicles,
  Get_Vehicles_SUCCESS,
  Get_Vehicles_ERROR,
  Get_Storages,
  Get_Storages_SUCCESS,
  Get_Storages_ERROR,
  Get_Drivers,
  Get_Drivers_SUCCESS,
  Get_Drivers_ERROR,
  Company_GET_LIST_WITH_FILTER,
  Company_GET_LIST_WITH_FILTER_SUCCESS,
  Company_GET_LIST_WITH_FILTER_ERROR,
  Get_Handovers,
  Get_Handovers_SUCCESS,
  Get_Handovers_ERROR,
  Get_Handover,
  Get_Handover_SUCCESS,
  Get_Handover_ERROR,
  Update_Handover,
  Update_Handover_SUCCESS,
  Update_Handover_ERROR,
  Get_Fees,
  Get_Fees_SUCCESS,
  Get_Fees_ERROR,
  Create_Fees,
  Create_Fees_SUCCESS,
  Create_Fees_ERROR,
  Update_Fees,
  Update_Fees_SUCCESS,
  Update_Fees_ERROR,
  Delete_Fees,
  Delete_Fees_SUCCESS,
  Delete_Fees_ERROR
} from "../actions";

const INIT_STATE = {
  pendingReqs: null,
  error: "",
  filter: null,
  searchKeyword: "",
  CompanyColumn: null,
  loading: false,
  company: null,
  errorVehicles: null,
  storages: null,
  loadingStorages: false,
  errorStorages: null,
  loadingDrivers: false,
  drivers: null,
  errorDrivers: null,
  errorUpdate: null,
  updateLoading: false,
  updatedCompany: null,
  handovers: [],
  handover: null,
  fees: [],
  loadingFees: false,
  loadingFeesCreate: false,
  loadingFeesUpdate: false,
  loadingFeesDelete: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case Get_Handovers:
      return { ...state, loading: false };

    case Get_Handovers_SUCCESS:
      return { ...state, loading: true, handovers: action.payload };

    case Get_Handovers_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Get_Handover:
      return { ...state, loading: true };

    case Get_Handover_SUCCESS:
      return { ...state, loading: false, handover: action.payload };

    case Get_Handover_ERROR:
      return { ...state, loading: false, error: action.payload };

    case Update_Handover:
      return { ...state };

    case Update_Handover_SUCCESS:
      return { ...state };

    case Update_Handover_ERROR:
      return { ...state, error: action.payload };

    case Get_Fees:
      return { ...state, loadingFees: true };

    case Get_Fees_SUCCESS:
      return { ...state, loadingFees: false, fees: action.payload };

    case Get_Fees_ERROR:
      return { ...state, loadingFees: false, error: action.payload };
      
    case Create_Fees:
      return { ...state, loadingFeesCreate: true };

    case Create_Fees_SUCCESS:
      return { ...state, loadingFeesCreate: false, fees: action.payload };

    case Create_Fees_ERROR:
      return { ...state, loadingFeesCreate: false, error: action.payload };

    case Update_Fees:
      return { ...state, loadingFeesUpdate: true };

    case Update_Fees_SUCCESS:
      return { ...state, loadingFeesUpdate: false, fees: action.payload };

    case Update_Fees_ERROR:
      return { ...state, loadingFeesUpdate: false, error: action.payload };

    case Delete_Fees:
      return { ...state, loadingFeesDelete: true };

    case Delete_Fees_SUCCESS:
      return { ...state, loadingFeesDelete: false, fees: action.payload };

    case Delete_Fees_ERROR:
      return { ...state, loadingFeesDelete: false, error: action.payload };
  
    case Pending_GET_LIST:
      return { ...state, loading: false };

    case Pending_GET_LIST_SUCCESS:
      return { ...state, loading: true, pendingReqs: action.payload };

    case Pending_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Pending_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case Pending_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, pendingReqs: action.payload };

    case Pending_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };
    case Approved_GET_LIST:
      return { ...state, loading: false };

    case Approved_GET_LIST_SUCCESS:
      return { ...state, loading: true, ApprovedReqs: action.payload };

    case Approved_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Approved_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case Approved_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, ApprovedReqs: action.payload };

    case Approved_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };
    case Rejected_GET_LIST:
      return { ...state, loading: false };

    case Rejected_GET_LIST_SUCCESS:
      return { ...state, loading: true, RejectedReqs: action.payload };

    case Rejected_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Rejected_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case Rejected_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, RejectedReqs: action.payload };

    case Rejected_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Company_UPDATE:
      return { ...state, updateLoading: false };

    case Company_UPDATE_SUCCESS:
      return { ...state, updateLoading: true, updatedCompany: action.payload };

    case Company_UPDATE_ERROR:
      return { ...state, updateLoading: true, errorUpdate: action.payload };

    case Get_Company_Detail:
      return { ...state, isLoading: false };

    case Get_Company_Detail_SUCCESS:
      return { ...state, isLoading: true, company: action.payload };

    case Get_Company_Detail_ERROR:
      return { ...state, isLoading: true, error: action.payload };
    case Get_Vehicles:
      return { ...state, loading: false };

    case Get_Vehicles_SUCCESS:
      return { ...state, loading: true, vehicles: action.payload };

    case Get_Vehicles_ERROR:
      return { ...state, loading: true, errorVehicles: action.payload };

    case Get_Storages:
      return { ...state, loadingStorages: false };

    case Get_Storages_SUCCESS:
      return { ...state, loadingStorages: true, storages: action.payload };

    case Get_Storages_ERROR:
      return { ...state, loadingStorages: true, errorStorages: action.payload };
    case Get_Drivers:
      return { ...state, loadingDrivers: false };

    case Get_Drivers_SUCCESS:
      return { ...state, loadingDrivers: true, drivers: action.payload };

    case Get_Drivers_ERROR:
      return { ...state, loadingDrivers: true, errorDrivers: action.payload };
    case Company_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case Company_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, pendingReqs: action.payload };

    case Company_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
