import {
  Pending_GET_LIST,
  Pending_GET_LIST_SUCCESS,
  Pending_GET_LIST_ERROR,
  Pending_GET_LIST_WITH_FILTER,
  Pending_GET_LIST_WITH_FILTER_SUCCESS,
  Pending_GET_LIST_WITH_FILTER_ERROR,
  Approved_GET_LIST,
  Approved_GET_LIST_SUCCESS,
  Approved_GET_LIST_ERROR,
  Approved_GET_LIST_WITH_FILTER,
  Approved_GET_LIST_WITH_FILTER_SUCCESS,
  Approved_GET_LIST_WITH_FILTER_ERROR,
  Rejected_GET_LIST,
  Rejected_GET_LIST_SUCCESS,
  Rejected_GET_LIST_ERROR,
  Rejected_GET_LIST_WITH_FILTER,
  Rejected_GET_LIST_WITH_FILTER_SUCCESS,
  Rejected_GET_LIST_WITH_FILTER_ERROR,
  Company_UPDATE,
  Company_UPDATE_SUCCESS,
  Company_UPDATE_ERROR,
  Get_Company_Detail,
  Get_Company_Detail_SUCCESS,
  Get_Company_Detail_ERROR,
  Get_Vehicles,
  Get_Vehicles_SUCCESS,
  Get_Vehicles_ERROR,
  Get_Storages,
  Get_Storages_SUCCESS,
  Get_Storages_ERROR,
  Get_Drivers,
  Get_Drivers_SUCCESS,
  Get_Drivers_ERROR,
  Company_GET_LIST_WITH_FILTER,
  Company_GET_LIST_WITH_FILTER_SUCCESS,
  Company_GET_LIST_WITH_FILTER_ERROR,
  Get_Handovers,
  Get_Handovers_SUCCESS,
  Get_Handovers_ERROR,
  Get_Handover,
  Get_Handover_SUCCESS,
  Get_Handover_ERROR,
  Update_Handover,
  Update_Handover_SUCCESS,
  Update_Handover_ERROR,
  Get_Fees,
  Get_Fees_SUCCESS,
  Get_Fees_ERROR,
  Create_Fees,
  Create_Fees_SUCCESS,
  Create_Fees_ERROR,
  Update_Fees,
  Update_Fees_SUCCESS,
  Update_Fees_ERROR,
  Delete_Fees,
  Delete_Fees_SUCCESS,
  Delete_Fees_ERROR
} from "../actions.js";

export const getHandovers = () => ({
  type: Get_Handovers,
});

export const getHandoversSuccess = (items) => ({
  type: Get_Handovers_SUCCESS,
  payload: items,
});

export const getHandoversError = (error) => ({
  type: Get_Handovers_ERROR,
  payload: error,
});

export const getHandover = (item) => ({
  type: Get_Handover,
  payload: item,
});

export const getHandoverSuccess = (items) => ({
  type: Get_Handover_SUCCESS,
  payload: items,
});

export const getHandoverError = (error) => ({
  type: Get_Handover_ERROR,
  payload: error,
});


export const updateHandover = (item) => ({
  type: Update_Handover,
  payload: item,
});

export const updateHandoverSuccess = (items) => ({
  type: Update_Handover_SUCCESS,
  payload: items,
});

export const updateHandoverError = (error) => ({
  type: Update_Handover_ERROR,
  payload: error,
});

export const getfees = (item) => ({
  type: Get_Fees,
  payload: item,
});

export const getfeesSuccess = (items) => ({
  type: Get_Fees_SUCCESS,
  payload: items,
});

export const getfeesError = (error) => ({
  type: Get_Fees_ERROR,
  payload: error,
});

export const createfees = (item) => ({
  type: Create_Fees,
  payload: item,
});

export const createfeesSuccess = (items) => ({
  type: Create_Fees_SUCCESS,
  payload: items,
});

export const createfeesError = (error) => ({
  type: Create_Fees_ERROR,
  payload: error,
});

export const updatefees = (item) => ({
  type: Update_Fees,
  payload: item,
});

export const updatefeesSuccess = (items) => ({
  type: Update_Fees_SUCCESS,
  payload: items,
});

export const updatefeesError = (error) => ({
  type: Update_Fees_ERROR,
  payload: error,
});

export const deletefees = (item) => ({
  type: Delete_Fees,
  payload: item,
});

export const deletefeesSuccess = (items) => ({
  type: Delete_Fees_SUCCESS,
  payload: items,
});

export const deletefeesError = (error) => ({
  type: Delete_Fees_ERROR,
  payload: error,
});


export const getPendingRequests = () => ({
  type: Pending_GET_LIST,
});

export const getPendingListSuccess = (items) => ({
  type: Pending_GET_LIST_SUCCESS,
  payload: items,
});

export const getPendingListError = (error) => ({
  type: Pending_GET_LIST_ERROR,
  payload: error,
});

export const getPendingListWithFilter = (column, value) => ({
  type: Pending_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getPendingListWithFilterSuccess = (items) => ({
  type: Pending_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getPendingListWithFilterError = (items) => ({
  type: Pending_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const getApprovedRequests = () => ({
  type: Approved_GET_LIST,
});

export const getApprovedListSuccess = (items) => ({
  type: Approved_GET_LIST_SUCCESS,
  payload: items,
});

export const getApprovedListError = (error) => ({
  type: Approved_GET_LIST_ERROR,
  payload: error,
});

export const getApprovedListWithFilter = (column, value) => ({
  type: Approved_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getApprovedListWithFilterSuccess = (items) => ({
  type: Approved_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getApprovedListWithFilterError = (items) => ({
  type: Approved_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const getRejectedRequests = () => ({
  type: Rejected_GET_LIST,
});

export const getRejectedListSuccess = (items) => ({
  type: Rejected_GET_LIST_SUCCESS,
  payload: items,
});

export const getRejectedListError = (error) => ({
  type: Rejected_GET_LIST_ERROR,
  payload: error,
});

export const getRejectedListWithFilter = (column, value) => ({
  type: Rejected_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getRejectedListWithFilterSuccess = (items) => ({
  type: Rejected_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getRejectedListWithFilterError = (items) => ({
  type: Rejected_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const updateCompany = (item) => ({
  type: Company_UPDATE,
  payload: item,
});

export const updateCompanySuccess = (item) => ({
  type: Company_UPDATE_SUCCESS,
  payload: item,
});

export const updateCompanyError = (item) => ({
  type: Company_UPDATE_ERROR,
  payload: item,
});
export const getCompanyDetail = (item) => ({
  type: Get_Company_Detail,
  payload: item,
});

export const getCompanyDetailSuccess = (item) => ({
  type: Get_Company_Detail_SUCCESS,
  payload: item,
});

export const getCompanyDetailError = (item) => ({
  type: Get_Company_Detail_ERROR,
  payload: item,
});

export const getVehicles = (obj) => ({
  type: Get_Vehicles,
  payload: obj,
});

export const getVehiclesSuccess = (items) => ({
  type: Get_Vehicles_SUCCESS,
  payload: items,
});

export const getVehiclesError = (error) => ({
  type: Get_Vehicles_ERROR,
  payload: error,
});
export const getStorages = (obj) => ({
  type: Get_Storages,
  payload: obj,
});

export const getStoragesSuccess = (items) => ({
  type: Get_Storages_SUCCESS,
  payload: items,
});

export const getStoragesError = (error) => ({
  type: Get_Storages_ERROR,
  payload: error,
});
export const getDrivers = (obj) => ({
  type: Get_Drivers,
  payload: obj,
});

export const getDriversSuccess = (items) => ({
  type: Get_Drivers_SUCCESS,
  payload: items,
});

export const getDriversError = (error) => ({
  type: Get_Drivers_ERROR,
  payload: error,
});

export const getCompanyFilter = (column, value) => ({
  type: Company_GET_LIST_WITH_FILTER,
  payload: { column, value },
});
export const getCompanyFilterSuccess = (items) => ({
  type: Company_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});

export const getCompanyFilterError = (error) => ({
  type: Company_GET_LIST_WITH_FILTER_ERROR,
  payload: error,
});
