import {
  Users_GET_LIST,
  Users_GET_LIST_SUCCESS,
  Users_GET_LIST_ERROR,
  Users_GET_LIST_WITH_FILTER,
  Users_GET_LIST_WITH_FILTER_SUCCESS,
  Users_GET_LIST_WITH_FILTER_ERROR,
  User_LOGS_GET,
  User_LOGS_GET_SUCCESS,
  User_LOGS_GET_ERROR,
  Users_GET_ALL,
  Users_GET_ALL_SUCCESS,
  Users_GET_ALL_ERROR,
} from "../actions.js";

export const getUsers = (payload) => ({
  type: Users_GET_LIST,
  payload: payload,
});

export const getUsersListSuccess = (items) => ({
  type: Users_GET_LIST_SUCCESS,
  payload: items,
});

export const getUsersListError = (error) => ({
  type: Users_GET_LIST_ERROR,
  payload: error,
});

export const getUsersListWithFilter = (column, value) => ({
  type: Users_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getUsersListWithFilterSuccess = (items) => ({
  type: Users_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getUsersListWithFilterError = (items) => ({
  type: Users_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const getUserLogs = (userId) => ({
  type: User_LOGS_GET,
  payload: userId,
});
export const getUserLogsSuccess = (user) => ({
  type: User_LOGS_GET_SUCCESS,
  payload: user,
});
export const getUserLogsError = () => ({
  type: User_LOGS_GET_ERROR,
});

export const getAllUsers = (payload) => ({
  type: Users_GET_ALL,
  payload: payload,
});

export const getAllUsersSuccess = (items) => ({
  type: Users_GET_ALL_SUCCESS,
  payload: items,
});

export const getAllUsersError = (error) => ({
  type: Users_GET_ALL_ERROR,
  payload: error,
});
