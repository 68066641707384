import {
    Levels_GET_LIST,
    Levels_GET_LIST_SUCCESS,
    Levels_GET_LIST_ERROR,
    Level_GET_DETAILS,
    Level_GET_DETAILS_SUCCESS,
    Level_GET_DETAILS_ERROR,
    Level_DELETE,
    Level_DELETE_SUCCESS,
    Levels_GET_LIST_WITH_FILTER,
    Levels_GET_LIST_WITH_FILTER_SUCCESS,
	Levels_GET_LIST_WITH_FILTER_ERROR,
	Level_ADD_ITEM,
	Level_ADD_ITEM_SUCCESS,
	Level_ADD_ITEM_ERROR,
	Level_ITEM_UPDATE_DETAILS,
	Level_ITEM_UPDATE_DETAILS_SUCCESS,
	Level_ITEM_UPDATE_DETAILS_ERROR
} from "../actions.js"

const INIT_STATE = {
	levelsList: null,
	Level:null,
	error: '',
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	categories: [],
	selectedOrders: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case Levels_GET_LIST:
			return { ...state, loading: false };

		case Levels_GET_LIST_SUCCESS:
			return { ...state, loading: true, levelsList: action.payload};

		case Levels_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };

		case Level_GET_DETAILS:
			return { ...state, isLoading: false };

		case Level_GET_DETAILS_SUCCESS:
			return { ...state, isLoading: true, Level: action.payload};

		case Level_GET_DETAILS_ERROR:
			return { ...state, isLoading: true, error: action.payload };

		case Levels_GET_LIST_WITH_FILTER:
			return { ...state, loading: false};

		case Levels_GET_LIST_WITH_FILTER_SUCCESS:
			return { ...state, loading: true, levelsList: action.payload};
		
		case Levels_GET_LIST_WITH_FILTER_ERROR:
			return { ...state, loading: true, error: action.payload };

		case Level_DELETE:
			return { ...state,isLoading:false};

		case Level_DELETE_SUCCESS:
			return { ...state, isLoading: true, level: action.payload};
			
		case Level_ADD_ITEM:
			return { ...state, loading: false };

		case Level_ADD_ITEM_SUCCESS:
			return { ...state, loading: true, levelsList: action.payload};

		case Level_ADD_ITEM_ERROR:
			return { ...state, loading: true, error: action.payload };
			
		case Level_ITEM_UPDATE_DETAILS:
			return { ...state,loading:false};

		case Level_ITEM_UPDATE_DETAILS_SUCCESS:
			return { ...state, loading: true, level: action.payload};

		case Level_ITEM_UPDATE_DETAILS_ERROR:
			return { ...state, loading: true, error: action.payload };
			


		default: return { ...state };

	}
}
