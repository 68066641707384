import {
  MatchedCars_GET_LIST,
  MatchedCars_GET_LIST_SUCCESS,
  MatchedCars_GET_LIST_ERROR,
  MatchedCars_GET_LIST_WITH_FILTER,
  MatchedCars_GET_LIST_WITH_FILTER_SUCCESS,
  MatchedCars_GET_LIST_WITH_FILTER_ERROR,
  MatchedCar_GET_DETAILS,
  MatchedCar_GET_DETAILS_SUCCESS,
  MatchedCar_GET_DETAILS_ERROR,
  Track_GET,
  Track_GET_SUCCESS,
  Track_GET_ERROR,
  MatchedCars_ALL,
  MatchedCars_ALL_SUCCESS,
  MatchedCars_ALL_ERROR,
} from "../actions.js";

export const getMatchedCarsList = (payload) => ({
  type: MatchedCars_GET_LIST,
  payload: payload,
});

export const getMatchedCarsListSuccess = (items) => ({
  type: MatchedCars_GET_LIST_SUCCESS,
  payload: items,
});

export const getMatchedCarsListError = (error) => ({
  type: MatchedCars_GET_LIST_ERROR,
  payload: error,
});

export const getMatchedCarsListWithFilter = (column, value) => ({
  type: MatchedCars_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getMatchedCarsListWithFilterSuccess = (items) => ({
  type: MatchedCars_GET_LIST_WITH_FILTER_SUCCESS,
  payload: items,
});
export const getMatchedCarsListWithFilterError = (items) => ({
  type: MatchedCars_GET_LIST_WITH_FILTER_ERROR,
  payload: items,
});
export const getMatchedCarDetail = (itemId) => ({
  type: MatchedCar_GET_DETAILS,
  payload: itemId,
});

export const getMatchedCarDetailSuccess = (items) => ({
  type: MatchedCar_GET_DETAILS_SUCCESS,
  payload: items,
});

export const getMatchedCarDetailError = (error) => ({
  type: MatchedCar_GET_DETAILS_ERROR,
  payload: error,
});
export const getTrackInfo = (itemId) => ({
  type: Track_GET,
  payload: itemId,
});

export const getTrackInfoSuccess = (item) => ({
  type: Track_GET_SUCCESS,
  payload: item,
});

export const getTrackInfoError = (error) => ({
  type: Track_GET_ERROR,
  payload: error,
});
export const getMatchedCars = (payload) => ({
  type: MatchedCars_ALL,
  payload: payload,
});

export const getMatchedCarsSuccess = (items) => ({
  type: MatchedCars_ALL_SUCCESS,
  payload: items,
});

export const getMatchedCarsError = (error) => ({
  type: MatchedCars_ALL_ERROR,
  payload: error,
});
