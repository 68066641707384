import {
    Levels_GET_LIST,
    Levels_GET_LIST_SUCCESS,
    Levels_GET_LIST_ERROR,
    Level_GET_DETAILS,
    Level_GET_DETAILS_SUCCESS,
    Level_GET_DETAILS_ERROR,
    Level_DELETE,
    Level_DELETE_SUCCESS,
    Levels_GET_LIST_WITH_FILTER,
    Levels_GET_LIST_WITH_FILTER_SUCCESS,
    Levels_GET_LIST_WITH_FILTER_ERROR,
    Level_ADD_ITEM,
    Level_ADD_ITEM_SUCCESS,
    Level_ADD_ITEM_ERROR,
    Level_ITEM_UPDATE_DETAILS,
	Level_ITEM_UPDATE_DETAILS_SUCCESS,
	Level_ITEM_UPDATE_DETAILS_ERROR
} from "../actions.js"

export const getLevelsList = () => ({
    type: Levels_GET_LIST
});

export const getLevelsListSuccess = (items) => ({
    type: Levels_GET_LIST_SUCCESS,
    payload: items
});

export const getLevelsListError = (error) => ({
    type: Levels_GET_LIST_ERROR,
    payload: error
});
export const getLevelDetail = (itemId) => ({
    type: Level_GET_DETAILS,
    payload: itemId
});

export const getLevelDetailSuccess = (items) => ({
    type: Level_GET_DETAILS_SUCCESS,
    payload: items
});

export const getLevelDetailError = (error) => ({
    type: Level_GET_DETAILS_ERROR,
    payload: error
});

export const getLevelsListWithFilter = (column, value) => ({
    type: Levels_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getLevelsListWithFilterSuccess = (items) => ({
    type: Levels_GET_LIST_WITH_FILTER_SUCCESS,
    payload: items
});

export const getLevelsListWithFilterError = (items) => ({
    type: Levels_GET_LIST_WITH_FILTER_ERROR,
    payload: items
});
export const addLevel = (item) => ({
    type: Level_ADD_ITEM,
    payload: item
});
export const addLevelSuccess = (items) => ({
    type: Level_ADD_ITEM_SUCCESS,
    payload: items
});

export const addLevelError = (error) => ({
    type: Level_ADD_ITEM_ERROR,
    payload: error
});

export const updateLevel = (item) => ({
    type: Level_ITEM_UPDATE_DETAILS,
    payload: item
});

export const updateLevelSuccess = (item) => ({
    type: Level_ITEM_UPDATE_DETAILS_SUCCESS,
    payload: item
});

export const updateLevelError = (item) => ({
    type: Level_ITEM_UPDATE_DETAILS_ERROR,
    payload: item
});
export const deleteLevel = (itemId,item) => ({
    type: Level_DELETE,
    payload: {itemId,item}
});
export const deleteLevelSuccess = (item) => ({
    type: Level_DELETE_SUCCESS,
    payload: item
});