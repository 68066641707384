
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { apiUrl_store, internal_API} from "../../constants/defaultValues";

import {
	STORE_GET_DETAILS,
	STORE_ITEM_UPDATE_DETAILS,
	STORE_ITEM_DELETE

} from '../actions';

import {
	getStoreDetailSuccess,
	getStoreDetailError,
	updateStoreItemSuccess,
	updateStoreItemError,
	deleteStoreItemSuccess
} from './actions';
import axios from 'axios';


const getStoreDetailRequest = async item => {
	let itemId = item.payload;
	return await axios.get(`${internal_API+apiUrl_store}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* getStoreDetailItem(payload) {
	try {
		const response = yield call(getStoreDetailRequest, payload);
		if(response && response.status === 200){
			yield put(getStoreDetailSuccess(response.data));
		}else{
			yield put(getStoreDetailError(response));
		}
	} catch (error) {
		yield put(getStoreDetailError(error));
	}
}

const updateStoreItemRequest = async itemObj => {
	let itemId = itemObj.id;
	let body = {
		"title": itemObj.itemTitle,
		"description": itemObj.itemDesc,
		"price": itemObj.itemPrice,
		"thumbnail": itemObj.itemThumbnail,
		"dynamicPrice":itemObj.itemDynamicPrice
	}
	return await axios.put(`${internal_API+apiUrl_store}/${itemId}`,body,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}
function* updateStoreItem({ payload }) {
	try {
	  const response = yield call(updateStoreItemRequest, payload);
	  if(response && response.status === 200 || response.status === 201){
		yield put(updateStoreItemSuccess(response.data));
	  }else{
		yield put(updateStoreItemError(response));
	  }
	} catch (error) {
	  yield put(updateStoreItemError(error));
	}
}

const deleteItemRequest = async (itemId, item) => {
	return await axios.delete(`${internal_API+apiUrl_store}/${itemId}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
}

function* deleteItem({ payload }) {
	try {
		const { itemId, item } = payload;
		const response = yield call(deleteItemRequest, itemId, item);
		if(response && response.status === 200 || response.status === 201){
			yield put(deleteStoreItemSuccess(response));
		  }else{
			yield put(getStoreDetailError(response));
		  }
	} catch (error) {
		yield put(getStoreDetailError(error));
	}
}

export function* watchDeleteStoreItem() {
	yield takeEvery(STORE_ITEM_DELETE, deleteItem);
}

export function* watchGetDetail() {
	yield takeEvery(STORE_GET_DETAILS, getStoreDetailItem);
}
export function* watchUpdateItem() {
	yield takeEvery(STORE_ITEM_UPDATE_DETAILS, updateStoreItem);
}


export default function* rootSaga() {
	yield all([
		fork(watchGetDetail),
		fork(watchUpdateItem),
		fork(watchDeleteStoreItem),
	]);
}