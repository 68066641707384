import {
    UsersMissions_GET_LIST,
    UsersMissions_GET_LIST_SUCCESS,
	UsersMissions_GET_LIST_ERROR,
	UsersMissions_GET_LIST_WITH_FILTER,
	UsersMissions_GET_LIST_WITH_FILTER_SUCCESS,
	UsersMissions_GET_LIST_WITH_FILTER_ERROR
} from '../actions';

const INIT_STATE = {
	usersMissionsList: null,
	error: '',
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	categories: [],
	selectedUsersMissions: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case UsersMissions_GET_LIST:
			return { ...state, loading: false };

		case UsersMissions_GET_LIST_SUCCESS:
			return { ...state, loading: true, usersMissionsList: action.payload };

		case UsersMissions_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };
            
		case UsersMissions_GET_LIST_WITH_FILTER:
			return { ...state, loading: false};

		case UsersMissions_GET_LIST_WITH_FILTER_SUCCESS:
			return { ...state, loading: true, usersMissionsList: action.payload};
		
		case UsersMissions_GET_LIST_WITH_FILTER_ERROR:
			return { ...state, loading: true, error: action.payload };
            
		default: return { ...state };

	}
}
