import {
  ReqCARS_GET_LIST,
  ReqCARS_GET_LIST_SUCCESS,
  ReqCARS_GET_LIST_ERROR,
  ReqCARS_GET_LIST_WITH_FILTER,
  ReqCARS_ADD_ITEM,
  ReqCARS_ADD_ITEM_SUCCESS,
  ReqCARS_ADD_ITEM_ERROR,
  ReqCARS_GET_LIST_WITH_FILTER_SUCCESS,
  ReqCARS_GET_LIST_WITH_FILTER_ERROR,
  ReqCAR_ITEM_UPDATE_DETAILS,
  ReqCAR_ITEM_UPDATE_DETAILS_SUCCESS,
  ReqCAR_ITEM_UPDATE_DETAILS_ERROR,
  ReqCAR_ITEM_DELETE,
  ReqCAR_ITEM_DELETE_SUCCESS,
  ReqCAR_GET_DETAIL,
  ReqCAR_GET_DETAIL_SUCCESS,
  ReqCAR_GET_DETAIL_ERROR,
  ReqCARS_GET_LIST_ALL,
  ReqCARS_GET_LIST_SUCCESS_ALL,
  ReqCARS_GET_LIST_ERROR_ALL,
} from "../actions";

const INIT_STATE = {
  allReqCarsList: null,
  reqCarsList: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  categories: [],
  selectedReqCars: [],
  loadingAll: false,
  requestedCars: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ReqCARS_GET_LIST:
      return { ...state, loading: false };

    case ReqCARS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allReqCarsList: action.payload,
        reqCarsList: action.payload,
      };

    case ReqCARS_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ReqCARS_ADD_ITEM:
      return { ...state, loading: false };

    case ReqCARS_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        requestedCar: action.payload,
      };

    case ReqCARS_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ReqCARS_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case ReqCARS_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, allReqCarsList: action.payload };

    case ReqCARS_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ReqCAR_GET_DETAIL:
      return { ...state, isLoading: false };

    case ReqCAR_GET_DETAIL_SUCCESS:
      return { ...state, isLoading: true, requestedCar: action.payload };

    case ReqCAR_GET_DETAIL_ERROR:
      return { ...state, isLoading: true, error: action.payload };

    case ReqCAR_ITEM_UPDATE_DETAILS:
      return { ...state, loading: false };

    case ReqCAR_ITEM_UPDATE_DETAILS_SUCCESS:
      return { ...state, loading: true, requestedCar: action.payload };

    case ReqCAR_ITEM_UPDATE_DETAILS_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ReqCAR_ITEM_DELETE:
      return { ...state, loading: false };

    case ReqCAR_ITEM_DELETE_SUCCESS:
      return { ...state, loading: true, requestedCar: action.payload };
    case ReqCARS_GET_LIST_ALL:
      return { ...state, loadingAll: false };

    case ReqCARS_GET_LIST_SUCCESS_ALL:
      return {
        ...state,
        loadingAll: true,
        requestedCars: action.payload,
      };

    case ReqCARS_GET_LIST_ERROR_ALL:
      return { ...state, loadingAll: true, error: action.payload };
    default:
      return { ...state };
  }
};
