import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  apiUrl_requestedCars,
  internal_API,
  apiUrl_filter,
} from "../../constants/defaultValues";

import {
  ReqCARS_GET_LIST,
  ReqCARS_ADD_ITEM,
  ReqCARS_GET_LIST_WITH_FILTER,
  ReqCAR_GET_DETAIL,
  ReqCAR_ITEM_UPDATE_DETAILS,
  ReqCAR_ITEM_DELETE,
  ReqCARS_GET_LIST_ALL,
} from "../actions";

import {
  getReqCarsListSuccess,
  getReqCarsListError,
  getReqCarsListWithFilterSuccess,
  getReqCarsListWithFilterError,
  addReqCarItemSuccess,
  addReqCarItemError,
  getReqCarDetailSuccess,
  getReqCarDetailError,
  updateRequestedCarSuccess,
  updateRequestedCarError,
  deleteRequestedCarSuccess,
  getAllReqCarsSuccess,
  getAllReqCarsError,
} from "./actions";
import axios from "axios";

const reqCarsListRequest = async ({ payload }) => {
  let url = internal_API + apiUrl_requestedCars;
  const { skip, pageSize } = payload || {};
  if (pageSize) {
    url = url + "?limit=" + pageSize + "&skip=" + skip;
  }
  return await axios
    .get(`${url}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getReqCarsList(payload) {
  try {
    const response = yield call(reqCarsListRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] =
        payload && payload.payload ? payload.payload.currentPage : 0;
      yield put(getReqCarsListSuccess(response.data));
    } else {
      yield put(getReqCarsListError(response));
    }
  } catch (error) {
    yield put(getReqCarsListError(error));
  }
}

const reqCarsFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "RequestedCars",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getFilteredReqCars({ payload }) {
  try {
    const response = yield call(reqCarsFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getReqCarsListWithFilterSuccess(response.data));
    } else {
      yield put(getReqCarsListWithFilterError(response));
    }
  } catch (error) {
    yield put(getReqCarsListWithFilterError("Not Found"));
  }
}

const requestedCarItemRequest = async (item) => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken"),
    },
  };
  var body = {
    licensePlateNumber: item.licensePlateNumber,
    licensePlateState: item.licensePlateState,
    licensePlateSpecialCharacters: item.licensePlateSpecialCharacters,
    vinNumber: item.vinNumber,
    model: item.model,
    make: item.make,
    year: item.year,
    color: item.color,
    rewardAmount: item.rewardAmount,
    founded: false,
    trim: item.trim,
    lienholderId: item.lienholderId
  };
  return await axios
    .post(`${internal_API + apiUrl_requestedCars}`, body, options)
    .then(async (response) => {
      return response;
    })
    .catch((error) => error);
};

function* addRequestedCarItem({ payload }) {
  try {
    const response = yield call(requestedCarItemRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(addReqCarItemSuccess(response.data));
    } else {
      yield put(addReqCarItemError(response));
    }
  } catch (error) {
    yield put(addReqCarItemError(error));
  }
}

const getRequestedCarRequest = async (item) => {
  let itemId = item.payload;
  return await axios
    .get(`${internal_API + apiUrl_requestedCars}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getRequestedCarItem(payload) {
  try {
    const response = yield call(getRequestedCarRequest, payload);
    if (response && (response.status === 200 || response.status == 201)) {
      yield put(getReqCarDetailSuccess(response.data));
    } else {
      yield put(getReqCarDetailError(response));
    }
  } catch (error) {
    yield put(getReqCarDetailError(error));
  }
}

const updateRequestedCarRequest = async (itemObj) => {
  let itemId = itemObj.id;
  let body = {
    licensePlateNumber: itemObj.licensePlateNumber,
    licensePlateState: itemObj.licensePlateState,
    licensePlateSpecialCharacters: itemObj.licensePlateSpecialCharacters,
    make: itemObj.make,
    vinNumber: itemObj.vinNumber,
    model: itemObj.model,
    year: itemObj.year,
    color: itemObj.color,
    rewardAmount: itemObj.rewardAmount,
    founded: itemObj.founded,
    trim: itemObj.trim,
  };
  return await axios
    .put(`${internal_API + apiUrl_requestedCars}/${itemId}`, body, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* updateRequestedCar({ payload }) {
  try {
    const response = yield call(updateRequestedCarRequest, payload);
    if ((response && response.status === 200) || response.status === 201) {
      yield put(updateRequestedCarSuccess(response.data));
    } else {
      yield put(updateRequestedCarError(response));
    }
  } catch (error) {
    yield put(updateRequestedCarError(error));
  }
}

const deleteItemRequest = async (itemId, item) => {
  return await axios
    .delete(`${internal_API + apiUrl_requestedCars}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* deleteItem({ payload }) {
  try {
    const { itemId, item } = payload;
    const response = yield call(deleteItemRequest, itemId, item);
    if ((response && response.status === 200) || response.status === 201) {
      yield put(deleteRequestedCarSuccess(response));
    } else {
      yield put(getReqCarDetailError(response));
    }
  } catch (error) {
    yield put(getReqCarDetailError(error));
  }
}
function* getAllReqCars() {
  try {
    const response = yield call(reqCarsListRequest, {});
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] = 0;
      yield put(getAllReqCarsSuccess(response.data));
    } else {
      yield put(getReqCarsListError(response));
    }
  } catch (error) {
    yield put(getAllReqCarsError(error));
  }
}
// Tip :- The watchGetRequestedCarsList generator pauses"stop" until an ReqCARS_GET_LIST action fires, and every time it fires, it’s going to call the getReqCarsList function, infinitely, and concurrently

export function* watchGetRequestedCarsList() {
  yield takeEvery(ReqCARS_GET_LIST, getReqCarsList);
}
export function* watchGetFilteredReqCars() {
  yield takeEvery(ReqCARS_GET_LIST_WITH_FILTER, getFilteredReqCars);
}

export function* watchAddItem() {
  yield takeEvery(ReqCARS_ADD_ITEM, addRequestedCarItem);
}

export function* watchRequestedCarDetail() {
  yield takeEvery(ReqCAR_GET_DETAIL, getRequestedCarItem);
}
export function* watchUpdateRequestedCar() {
  yield takeEvery(ReqCAR_ITEM_UPDATE_DETAILS, updateRequestedCar);
}
export function* watchDeleteItem() {
  yield takeEvery(ReqCAR_ITEM_DELETE, deleteItem);
}
export function* watchGetRequestedCars() {
  yield takeEvery(ReqCARS_GET_LIST_ALL, getAllReqCars);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetRequestedCarsList),
    fork(watchGetFilteredReqCars),
    fork(watchAddItem),
    fork(watchRequestedCarDetail),
    fork(watchUpdateRequestedCar),
    fork(watchDeleteItem),
    fork(watchGetRequestedCars),
  ]);
}
