import {
    STORE_GET_DETAILS,
    STORE_GET_DETAILS_SUCCESS,
    STORE_GET_DETAILS_ERROR,
    STORE_ITEM_UPDATE_DETAILS,
	STORE_ITEM_UPDATE_DETAILS_SUCCESS,
    STORE_ITEM_UPDATE_DETAILS_ERROR,
    STORE_ITEM_DELETE,
    STORE_ITEM_DELETE_SUCCESS
} from '../actions';

export const getStoreItemDetail = (itemId) => ({
    type: STORE_GET_DETAILS,
    payload: itemId
});

export const getStoreDetailSuccess = (items) => ({
    type: STORE_GET_DETAILS_SUCCESS,
    payload: items
});

export const getStoreDetailError = (error) => ({
    type: STORE_GET_DETAILS_ERROR,
    payload: error
});
export const updateStoreItem = (item) => ({
    type: STORE_ITEM_UPDATE_DETAILS,
    payload: item
});
export const updateStoreItemSuccess = (item) => ({
    type: STORE_ITEM_UPDATE_DETAILS_SUCCESS,
    payload: item
});
export const updateStoreItemError = (item) => ({
    type: STORE_ITEM_UPDATE_DETAILS_ERROR,
    payload: item
});

export const deleteStoreItem = (itemId,item) => ({
    type: STORE_ITEM_DELETE,
    payload: {itemId,item}
});
export const deleteStoreItemSuccess = (item) => ({
    type: STORE_ITEM_DELETE_SUCCESS,
    payload: item
});