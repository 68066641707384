import {
    ORDERS_GET_LIST,
    ORDERS_GET_LIST_SUCCESS,
	ORDERS_GET_LIST_ERROR,
	ALPHA_GET,
    ALPHA_GET_SUCCESS,
	ALPHA_GET_ERROR,
	ALPHA_UPDATE,
    ALPHA_UPDATE_SUCCESS,
	ALPHA_UPDATE_ERROR,
	ANDROID_GET,
    ANDROID_GET_SUCCESS,
	ANDROID_GET_ERROR,
	ORDER_GET_DETAILS,
    ORDER_GET_DETAILS_SUCCESS,
    ORDER_GET_DETAILS_ERROR,
	ORDER_DELETE,
	ORDER_DELETE_SUCCESS,
	ORDERS_GET_LIST_WITH_FILTER,
	ORDERS_GET_LIST_WITH_FILTER_SUCCESS,
	ORDERS_GET_LIST_WITH_FILTER_ERROR,
	ORDER_UPDATE,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_ERROR
} from '../actions';

const INIT_STATE = {
	ordersList: null,
	error: '',
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	categories: [],
	selectedOrders: [],
	alphaUsers: null,
	androidUsers: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ORDERS_GET_LIST:
			return { ...state, loading: false };

		case ORDERS_GET_LIST_SUCCESS:
			return { ...state, loading: true, ordersList: action.payload};

		case ORDERS_GET_LIST_ERROR:
			return { ...state, loading: true, error: action.payload };
			
		case ALPHA_GET:
			return { ...state, loading: false };

		case ALPHA_GET_SUCCESS:
			return { ...state, loading: true, alphaUsers: action.payload};

		case ALPHA_GET_ERROR:
			return { ...state, loading: true, error: action.payload };

		case ALPHA_UPDATE:
			return { ...state, loading: false };

		case ALPHA_UPDATE_SUCCESS:
			return { ...state, loading: true};

		case ALPHA_UPDATE_ERROR:
			return { ...state, loading: true, error: action.payload };

		case ANDROID_GET:
			return { ...state, loading: false };

		case ANDROID_GET_SUCCESS:
			return { ...state, loading: true, androidUsers: action.payload};

		case ANDROID_GET_ERROR:
			return { ...state, loading: true, error: action.payload };

		case ORDER_GET_DETAILS:
			return { ...state, isLoading: false };

		case ORDER_GET_DETAILS_SUCCESS:
			return { ...state, isLoading: true, order: action.payload};

		case ORDER_GET_DETAILS_ERROR:
			return { ...state, isLoading: true, error: action.payload };

		case ORDERS_GET_LIST_WITH_FILTER:
			return { ...state, loading: false};

		case ORDERS_GET_LIST_WITH_FILTER_SUCCESS:
			return { ...state, loading: true, ordersList: action.payload};
		
		case ORDERS_GET_LIST_WITH_FILTER_ERROR:
			return { ...state, loading: true, error: action.payload };

		case ORDER_DELETE:
			return { ...state,isLoading:false};

		case ORDER_DELETE_SUCCESS:
			return { ...state, isLoading: true, order: action.payload};

		case ORDER_UPDATE:
			return { ...state, updateLoading: false };

		case ORDER_UPDATE_SUCCESS:
			return { ...state, updateLoading: true, updatedOrder: action.payload};
	
		case ORDER_UPDATE_ERROR:
			return { ...state, updateLoading: true, errorUpdate: action.payload };

		default: return { ...state };
	}
}
