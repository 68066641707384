import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  internal_API,
  apiUrl_filter,
  apiUrl_companies,
  apiUrl_vehicles,
  apiUrl_storages,
  apiUrl_drivers,
  apiUrl_handovers,
  apiUrl_fee,
  apiUrl_publicKey,
  driver_API,
} from "../../constants/defaultValues";

import {
  Pending_GET_LIST,
  Pending_GET_LIST_WITH_FILTER,
  Approved_GET_LIST,
  Approved_GET_LIST_WITH_FILTER,
  Rejected_GET_LIST,
  Rejected_GET_LIST_WITH_FILTER,
  Company_UPDATE,
  Get_Company_Detail,
  Get_Vehicles,
  Get_Storages,
  Get_Drivers,
  Company_GET_LIST_WITH_FILTER,
  Get_Handovers,
  Get_Handover,
  Update_Handover,
  Get_Fees,
  Create_Fees,
  Update_Fees,
  Delete_Fees
} from "../actions";

import {
  getPendingListSuccess,
  getPendingListError,
  getPendingListWithFilterSuccess,
  getPendingListWithFilterError,
  getApprovedListSuccess,
  getApprovedListError,
  getApprovedListWithFilterSuccess,
  getApprovedListWithFilterError,
  getRejectedListSuccess,
  getRejectedListError,
  getRejectedListWithFilterSuccess,
  getRejectedListWithFilterError,
  updateCompanySuccess,
  updateCompanyError,
  getCompanyDetailSuccess,
  getCompanyDetailError,
  getVehiclesSuccess,
  getVehiclesError,
  getStoragesError,
  getStoragesSuccess,
  getDriversError,
  getDriversSuccess,
  getCompanyFilterSuccess,
  getCompanyFilterError,
  getHandoversSuccess,
  getHandoversError,
  getHandoverSuccess,
  getHandoverError,
  updateHandoverSuccess,
  updateHandoverError,
  getfeesSuccess,
  getfeesError,
  createfeesSuccess,
  createfeesError,
  updatefeesSuccess,
  updatefeesError,
  deletefeesSuccess,
  deletefeesError,
} from "./actions";
import axios from "axios";

const GetHandovers = async () => {
  return await axios
    .get(
      `${driver_API + apiUrl_handovers}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      console.log(response, "ddddsss")
      return response;
    })
    .catch((error) => error);
};

function* getHandoversAction() {
  try {
    const response = yield call(GetHandovers);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getHandoversSuccess(response.data));
    } else {
      yield put(getHandoversError(response));
    }
  } catch (error) {
    yield put(getHandoversError(error));
  }
}

const GetHandover = async (payload) => {
  return await axios
    .get(
      `${driver_API + apiUrl_handovers + '/' + payload}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => error);
};

function* getHandoverAction({payload}) {
  try {
    const response = yield call(GetHandover, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getHandoverSuccess(response.data));
    } else {
      yield put(getHandoverError(response));
    }
  } catch (error) {
    yield put(getHandoverError(error));
  }
}


const UpdateHandover = async (payload) => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken_company"),
    },
  };
  return await axios
    .put(
      `${driver_API + apiUrl_handovers + '/' + payload.id}`,
      payload.body,
      options,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => error);
};

function* updateHandoverAction({payload}) {
  try {
    const response = yield call(UpdateHandover, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(updateHandoverSuccess(response.data));
    } else {
      yield put(updateHandoverError(response));
    }
  } catch (error) {
    yield put(updateHandoverError(error));
  }
}

// fees
const GetFees = async (payload) => {
  return await axios
    .get(
      `${driver_API + apiUrl_fee + '/' + payload}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => error);
};

function* getFeesAction({payload}) {
  try {
    const response = yield call(GetFees, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getfeesSuccess(response.data));
    } else {
      yield put(getfeesError(response));
    }
  } catch (error) {
    yield put(getfeesError(error));
  }
}

const CreateFees = async (payload) => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken_company"),
    },
  };
  return await axios
    .post(
      `${driver_API + apiUrl_fee}`,
      payload,
      options,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => error);
};

function* createFeesAction({payload}) {
  try {
    const response = yield call(CreateFees, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(createfeesSuccess(response.data));
    } else {
      yield put(createfeesError(response));
    }
  } catch (error) {
    yield put(createfeesError(error));
  }
}

const UpdateFees = async (payload) => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem("jwtToken_company"),
    },
  };
  return await axios
    .put(
      `${driver_API + apiUrl_fee + '/' + payload.id}`,
      {
        status: payload.status,
        returnReason: payload.retuenReason
      },
      options,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => error);
};

function* updateFeesAction({payload}) {
  try {
    const response = yield call(UpdateFees, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(updatefeesSuccess(response.data));
    } else {
      yield put(updatefeesError(response));
    }
  } catch (error) {
    yield put(updatefeesError(error));
  }
}

const DeleteFees = async (payload) => {
  return await axios
    .delete(
      `${driver_API + apiUrl_fee + '/' + payload}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken_company"),
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => error);
};

function* deleteFeesAction({payload}) {
  try {
    const response = yield call(DeleteFees, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(deletefeesSuccess(response.data));
    } else {
      yield put(deletefeesError(response));
    }
  } catch (error) {
    yield put(deletefeesError(error));
  }
}

const PendingListRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_companies + "/pending"}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getPendingList() {
  try {
    const response = yield call(PendingListRequest);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getPendingListSuccess(response.data));
    } else {
      yield put(getPendingListError(response));
    }
  } catch (error) {
    yield put(getPendingListError(error));
  }
}

const PendingFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "Pending",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getFilteredPending({ payload }) {
  try {
    const response = yield call(PendingFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getPendingListWithFilterSuccess(response.data));
    } else {
      yield put(getPendingListWithFilterError(response));
    }
  } catch (error) {
    yield put(getPendingListWithFilterError("Not Found"));
  }
}
const ApprovedListRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_companies + "/approved"}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getApprovedList() {
  try {
    const response = yield call(ApprovedListRequest);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getApprovedListSuccess(response.data));
    } else {
      yield put(getApprovedListError(response));
    }
  } catch (error) {
    yield put(getApprovedListError(error));
  }
}

const ApprovedFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "Approved",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getFilteredApproved({ payload }) {
  try {
    const response = yield call(ApprovedFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getApprovedListWithFilterSuccess(response.data));
    } else {
      yield put(getApprovedListWithFilterError(response));
    }
  } catch (error) {
    yield put(getApprovedListWithFilterError("Not Found"));
  }
}
const RejectedListRequest = async () => {
  return await axios
    .get(`${internal_API + apiUrl_companies + "/rejected"}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getRejectedList() {
  try {
    const response = yield call(RejectedListRequest);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getRejectedListSuccess(response.data));
    } else {
      yield put(getRejectedListError(response));
    }
  } catch (error) {
    yield put(getRejectedListError(error));
  }
}

const RejectedFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "Rejected",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getFilteredRejected({ payload }) {
  try {
    const response = yield call(RejectedFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getRejectedListWithFilterSuccess(response.data));
    } else {
      yield put(getRejectedListWithFilterError(response));
    }
  } catch (error) {
    yield put(getRejectedListWithFilterError("Not Found"));
  }
}
const updateCompanyRequest = async (itemObj) => {
  let itemId = itemObj.itemId;
  var body = {};
  if (itemObj.status) {
    body["status"] = itemObj.status;
  }
  if (itemObj.papersStatus) {
    body["papersStatus"] = itemObj.papersStatus;
  }
  return await axios
    .put(`${internal_API + apiUrl_companies}/${itemId}`, body, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
const getCompanyRequest = async (itemId) => {
  return await axios
    .get(`${internal_API + apiUrl_companies}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
const getVehiclesRequest = async (payload) => {
  const { skip, pageSize, companyId } = payload;
  return await axios
    .get(
      `${
        internal_API + apiUrl_vehicles
      }/${companyId}?limit=${pageSize}&skip=${skip}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken"),
        },
      }
    )
    .then((response) => response)
    .catch((error) => error);
};
const getStoragesRequest = async (payload) => {
  const { skip, pageSize, companyId } = payload;
  return await axios
    .get(
      `${
        internal_API + apiUrl_storages
      }/${companyId}?limit=${pageSize}&skip=${skip}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken"),
        },
      }
    )
    .then((response) => response)
    .catch((error) => error);
};

const getDriversRequest = async (payload) => {
  const { skip, pageSize, companyId } = payload;
  return await axios
    .get(
      `${
        internal_API + apiUrl_drivers
      }/${companyId}?limit=${pageSize}&skip=${skip}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken"),
        },
      }
    )
    .then((response) => response)
    .catch((error) => error);
};
const companyFilterRequest = async (payload) => {
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "companies",
  });
  return await axios
    .get(`${internal_API + apiUrl_companies + "/filter"}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* updateCompany({ payload }) {
  try {
    const response = yield call(updateCompanyRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(updateCompanySuccess(response.data));
    } else {
      yield put(updateCompanyError(response));
    }
  } catch (error) {
    yield put(updateCompanyError("Not Found"));
  }
}

function* getCompany({ payload }) {
  try {
    const response = yield call(getCompanyRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getCompanyDetailSuccess(response.data));
    } else {
      yield put(getCompanyDetailError(response));
    }
  } catch (error) {
    yield put(getCompanyDetailError("Not Found"));
  }
}

function* getVehicles({ payload }) {
  try {
    const response = yield call(getVehiclesRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] = payload.currentPage;
      yield put(getVehiclesSuccess(response.data));
    } else {
      yield put(getVehiclesError(response));
    }
  } catch (error) {
    yield put(getVehiclesError(error));
  }
}
function* getStorages({ payload }) {
  try {
    const response = yield call(getStoragesRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      let result;
      if (response.data) {
        response.data["currentPage"] = payload.currentPage;
        result = response.data;
      } else {
        result = { count: 0, rows: [], currentPage: 0 };
      }
      yield put(getStoragesSuccess(result));
    } else {
      yield put(getStoragesError(response));
    }
  } catch (error) {
    yield put(getStoragesError(error));
  }
}

function* getDrivers({ payload }) {
  try {
    const response = yield call(getDriversRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] = payload.currentPage;
      yield put(getDriversSuccess(response.data));
    } else {
      yield put(getDriversError(response));
    }
  } catch (error) {
    yield put(getDriversError(error));
  }
}
function* getCompanyFilter({ payload }) {
  try {
    const response = yield call(companyFilterRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getCompanyFilterSuccess(response.data));
    } else {
      yield put(getCompanyFilterError(response));
    }
  } catch (error) {
    yield put(getCompanyFilterError(error));
  }
}
export function* watchGetHandovers() {
  yield takeEvery(Get_Handovers, getHandoversAction);
}

export function* watchGetHandover() {
  yield takeEvery(Get_Handover, getHandoverAction);
}

export function* watchUpdateHandover() {
  yield takeEvery(Update_Handover, updateHandoverAction);
}

export function* watchGetFees() {
  yield takeEvery(Get_Fees, getFeesAction);
}

export function* watchCreateFees() {
  yield takeEvery(Create_Fees, createFeesAction);
}

export function* watchUpdateFees() {
  yield takeEvery(Update_Fees, updateFeesAction);
}

export function* watchDeleteFees() {
  yield takeEvery(Delete_Fees, deleteFeesAction);
}
export function* watchGetPendingList() {
  yield takeEvery(Pending_GET_LIST, getPendingList);
}
export function* watchGetFilteredPending() {
  yield takeEvery(Pending_GET_LIST_WITH_FILTER, getFilteredPending);
}
export function* watchGetApprovedList() {
  yield takeEvery(Approved_GET_LIST, getApprovedList);
}
export function* watchGetFilteredApproved() {
  yield takeEvery(Approved_GET_LIST_WITH_FILTER, getFilteredApproved);
}
export function* watchGetRejectedList() {
  yield takeEvery(Rejected_GET_LIST, getRejectedList);
}
export function* watchGetFilteredRejected() {
  yield takeEvery(Rejected_GET_LIST_WITH_FILTER, getFilteredRejected);
}

export function* watchGetUpdateCompany() {
  yield takeEvery(Company_UPDATE, updateCompany);
}

export function* watchGetCompany() {
  yield takeEvery(Get_Company_Detail, getCompany);
}

export function* watchGetVehicles() {
  yield takeEvery(Get_Vehicles, getVehicles);
}

export function* watchGetStorages() {
  yield takeEvery(Get_Storages, getStorages);
}
export function* watchGetDrivers() {
  yield takeEvery(Get_Drivers, getDrivers);
}
export function* watchGetCompanyFilter() {
  yield takeEvery(Company_GET_LIST_WITH_FILTER, getCompanyFilter);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetHandovers),
    fork(watchGetHandover),
    fork(watchUpdateHandover),
    fork(watchGetFees),
    fork(watchCreateFees),
    fork(watchUpdateFees),
    fork(watchDeleteFees),
    fork(watchGetPendingList),
    fork(watchGetFilteredPending),
    fork(watchGetApprovedList),
    fork(watchGetRejectedList),
    fork(watchGetUpdateCompany),
    fork(watchGetCompany),
    fork(watchGetVehicles),
    fork(watchGetStorages),
    fork(watchGetDrivers),
    fork(watchGetCompanyFilter),
  ]);
}
