import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {internal_API , apiUrl_statistics } from "../../constants/defaultValues";

import {
    Statistics_GET_LIST,
} from "../actions.js"

import {
    getStatisticsListSuccess,
    getStatisticsListError
  } from "./actions";

  import axios from 'axios';


const statisticsRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_statistics}`,{
          headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
          }
      })
      .then(response => response)
      .catch(error => error);
};
  
function* getStatistics() {
  try {
      const response = yield call(statisticsRequest);
      if(response && (response.status === 200 || response.status === 201)){
        yield put(getStatisticsListSuccess(response.data));
      }else{
        yield put(getStatisticsListError(response));
      }
  } catch (error) {
        yield put(getStatisticsListError(error));
  }
}

export function* watchGetStatistics() {
  yield takeEvery(Statistics_GET_LIST, getStatistics);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetStatistics)
  ]);
}
