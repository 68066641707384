import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import storeSaga from "./store/saga";
import storeItemDetail from "./storeDetail/saga";
import requestedCarsList from "./requestedCarsList/saga";
import ordersList from "./orders/saga";
import missionsList from "./missions/saga";
import scannedCars from "./scannedCars/saga";
import matchedCars from "./matchedCars/saga";
import levels from "./levels/saga";
import usersMissionsList from "./usersMissions/saga";
import configurations from "./configurations/saga";
import statistics from "./statistics/saga";
import users from "./users/saga";
import companies from "./companies/saga";
import lienholder from "./lienholder/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    storeSaga(),
    storeItemDetail(),
    requestedCarsList(),
    ordersList(),
    missionsList(),
    scannedCars(),
    matchedCars(),
    levels(),
    usersMissionsList(),
    configurations(),
    statistics(),
    users(),
    companies(),
    lienholder(),
  ]);
}
