import {
	STORE_LIST_ADD_ITEM,
	STORE_LIST_ADD_ITEM_SUCCESS,
	STORE_LIST_ADD_ITEM_ERROR,
	STORE_LIST_SELECTED_ITEMS_CHANGE,
	STORE_LIST_GET_LIST,
	STORE_LIST_GET_LIST_SUCCESS,
	STORE_LIST_GET_LIST_ERROR,
	STORE_LIST_GET_LIST_SEARCH

} from '../actions';

const INIT_STATE = {
	allStoreItems: null,
	storeItems: null,
	error: '',
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	categories: [],
	selectedItems: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case STORE_LIST_GET_LIST:
			return { ...state, loading: false };

		case STORE_LIST_GET_LIST_SUCCESS:
			return { ...state, loading: true, allStoreItems: action.payload, StoreItems: action.payload , isLoading:true};

		case STORE_LIST_GET_LIST_ERROR:
			return { ...state, loading: true, error: action.payload };
		case STORE_LIST_ADD_ITEM:
			return { ...state, loading: false };

		case STORE_LIST_ADD_ITEM_SUCCESS:
			return { ...state, loading: true, allStoreItems: action.payload, StoreItems: action.payload };

		case STORE_LIST_ADD_ITEM_ERROR:
			return { ...state, loading: true, error: action.payload };

		case STORE_LIST_GET_LIST_SEARCH:
		if (action.payload === '') {
			return { ...state, allStoreItems: state.allStoreItems };
		} else {
			const keyword = action.payload.keyword.toLowerCase();
			const searchType = action.payload.searchType;
			const searchItems = state.allStoreItems.filter((item) =>
			(searchType == "title" ? item.title.toLowerCase().indexOf(keyword) > -1 : item[`${searchType}`] == keyword))
			return { ...state, loading: true, allStoreItems: searchItems, searchKeyword: action.payload.keyword }
		}
		
		case STORE_LIST_SELECTED_ITEMS_CHANGE:
			return { ...state, loading: true, selectedItems: action.payload};
		default: return { ...state };

	}
}
