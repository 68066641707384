import {
  ScannedCars_GET_LIST,
  ScannedCars_GET_LIST_SUCCESS,
  ScannedCars_GET_LIST_ERROR,
  ScannedCars_GET_LIST_WITH_FILTER,
  ScannedCars_GET_LIST_WITH_FILTER_SUCCESS,
  ScannedCars_GET_LIST_WITH_FILTER_ERROR,
  ScannedCar_GET_DETAILS,
  ScannedCar_GET_DETAILS_SUCCESS,
  ScannedCar_GET_DETAILS_ERROR,
  ScannedCar_GET_ALL,
  ScannedCar_GET_ALL_SUCCESS,
  ScannedCar_GET_ALL_ERROR,
} from "../actions";

const INIT_STATE = {
  scannedCarsList: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  categories: [],
  selectedScannedCars: [],
  errorAllScannedCars: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ScannedCars_GET_LIST:
      return { ...state, loading: false };

    case ScannedCars_GET_LIST_SUCCESS:
      return { ...state, loading: true, scannedCarsList: action.payload };

    case ScannedCars_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ScannedCars_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case ScannedCars_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, scannedCarsList: action.payload };

    case ScannedCars_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ScannedCar_GET_DETAILS:
      return { ...state, isLoading: false };

    case ScannedCar_GET_DETAILS_SUCCESS:
      return { ...state, isLoading: true, scannedCar: action.payload };

    case ScannedCar_GET_DETAILS_ERROR:
      return { ...state, isLoading: true, error: action.payload };

    case ScannedCar_GET_ALL:
      return { ...state, loadingAll: false };

    case ScannedCar_GET_ALL_SUCCESS:
      return { ...state, loadingAll: true, allScannedCars: action.payload };

    case ScannedCar_GET_ALL_ERROR:
      return {
        ...state,
        loadingAll: true,
        errorAllScannedCars: action.payload,
      };

    default:
      return { ...state };
  }
};
