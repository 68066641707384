import {
  Users_GET_LIST,
  Users_GET_LIST_SUCCESS,
  Users_GET_LIST_ERROR,
  Users_GET_LIST_WITH_FILTER,
  Users_GET_LIST_WITH_FILTER_SUCCESS,
  Users_GET_LIST_WITH_FILTER_ERROR,
  User_LOGS_GET,
  User_LOGS_GET_SUCCESS,
  User_LOGS_GET_ERROR,
  Users_GET_ALL,
  Users_GET_ALL_SUCCESS,
  Users_GET_ALL_ERROR,
} from "../actions";

const INIT_STATE = {
  usersList: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  categories: [],
  selectedUsers: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Users_GET_LIST:
      return { ...state, loading: false };

    case Users_GET_LIST_SUCCESS:
      return { ...state, loading: true, usersList: action.payload };

    case Users_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Users_GET_LIST_WITH_FILTER:
      return { ...state, loading: false };

    case Users_GET_LIST_WITH_FILTER_SUCCESS:
      return { ...state, loading: true, usersList: action.payload };

    case Users_GET_LIST_WITH_FILTER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case User_LOGS_GET:
      return { ...state, logsLoading: false };

    case User_LOGS_GET_SUCCESS:
      return { ...state, logsLoading: true, userLog: action.payload };

    case User_LOGS_GET_ERROR:
      return { ...state, logsLoading: true, error: action.payload };
    case Users_GET_ALL:
      return { ...state, loadingAll: false };

    case Users_GET_ALL_SUCCESS:
      return { ...state, loadingAll: true, allUsers: action.payload };

    case Users_GET_ALL_ERROR:
      return {
        ...state,
        loadingAll: true,
        errorAllUsers: action.payload,
      };
    default:
      return { ...state };
  }
};
