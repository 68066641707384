import {
    STORE_LIST_GET_LIST_SUCCESS,
    STORE_LIST_GET_LIST,
    STORE_LIST_ADD_ITEM,
    STORE_LIST_ADD_ITEM_SUCCESS,
    STORE_LIST_ADD_ITEM_ERROR,
    STORE_LIST_GET_LIST_ERROR,
    STORE_LIST_GET_LIST_SEARCH

} from '../actions';
export const getStoreList = () => ({
    type: STORE_LIST_GET_LIST
});

export const getStoreListSuccess = (items) => ({
    type: STORE_LIST_GET_LIST_SUCCESS,
    payload: items
});

export const getStoreListError = (error) => ({
    type: STORE_LIST_GET_LIST_ERROR,
    payload: error
});
export const addStoreItem = (item) => ({
    type: STORE_LIST_ADD_ITEM,
    payload: item
});
export const addStoreItemSuccess = (item) => ({
    type: STORE_LIST_ADD_ITEM_SUCCESS,
    payload: item
});

export const addStoreItemError = (error) => ({
    type: STORE_LIST_ADD_ITEM_ERROR,
    payload: error
});
export const getStoreListSearch = (keyword,searchType) => ({
    type: STORE_LIST_GET_LIST_SEARCH,
    payload: {keyword:keyword,searchType:searchType}
});
