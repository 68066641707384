import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  apiUrl_scannedCars,
  internal_API,
  apiUrl_filter,
} from "../../constants/defaultValues";

import {
  ScannedCars_GET_LIST,
  ScannedCars_GET_LIST_WITH_FILTER,
  ScannedCar_GET_DETAILS,
  ScannedCar_GET_ALL,
} from "../actions";

import {
  getScannedCarsListSuccess,
  getScannedCarsListError,
  getScannedCarsListWithFilterSuccess,
  getScannedCarsListWithFilterError,
  getScannedCarDetailSuccess,
  getScannedCarDetailError,
  getAllScannedCarsError,
  getAllScannedCarsSuccess,
} from "./actions";
import axios from "axios";

const scannedCarsListRequest = async ({ payload }) => {
  const { skip, pageSize } = payload;
  return await axios
    .get(
      `${internal_API + apiUrl_scannedCars}?limit=${pageSize}&skip=${skip}`,
      {
        headers: {
          "x-auth-token": localStorage.getItem("jwtToken"),
        },
      }
    )
    .then((response) => response)
    .catch((error) => error);
};

function* getScannedCarsList(payload) {
  try {
    const response = yield call(scannedCarsListRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] = payload.payload.currentPage;
      yield put(getScannedCarsListSuccess(response.data));
    } else {
      yield put(getScannedCarsListError(response));
    }
  } catch (error) {
    yield put(getScannedCarsListError(error));
  }
}

const scannedCarsFilterRequest = async (payload) => {
  if (payload.column == "spy" || payload.column == "matched") {
    payload.value = payload.value === "true" ? true : false;
  }
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "scannedCars",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* getFilteredScannedCars({ payload }) {
  try {
    const response = yield call(scannedCarsFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getScannedCarsListWithFilterSuccess(response.data));
    } else {
      yield put(getScannedCarsListWithFilterError(response));
    }
  } catch (error) {
    yield put(getScannedCarsListWithFilterError("Not Found"));
  }
}

const getScannedCarRequest = async (item) => {
  let itemId = item.payload;
  return await axios
    .get(`${internal_API + apiUrl_scannedCars}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
const getAllScannedCarsReq = async () => {
  return await axios
    .get(`${internal_API + apiUrl_scannedCars}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* getScannedCarItem(payload) {
  try {
    const response = yield call(getScannedCarRequest, payload);
    if (response && (response.status === 200 || response.status == 201)) {
      yield put(getScannedCarDetailSuccess(response.data));
    } else {
      yield put(getScannedCarDetailError(response));
    }
  } catch (error) {
    yield put(getScannedCarDetailError(error));
  }
}
function* getAllScannedCars() {
  try {
    const response = yield call(getAllScannedCarsReq);
    if (response && (response.status === 200 || response.status == 201)) {
      yield put(getAllScannedCarsSuccess(response.data));
    } else {
      yield put(getAllScannedCarsError(response));
    }
  } catch (error) {
    yield put(getAllScannedCarsError(error));
  }
}
export function* watchGetScannedCarsList() {
  yield takeEvery(ScannedCars_GET_LIST, getScannedCarsList);
}
export function* watchGetFilteredScannedCars() {
  yield takeEvery(ScannedCars_GET_LIST_WITH_FILTER, getFilteredScannedCars);
}
export function* watchGetScannedCarItem() {
  yield takeEvery(ScannedCar_GET_DETAILS, getScannedCarItem);
}
export function* watchGetAllScannedCars() {
  yield takeEvery(ScannedCar_GET_ALL, getAllScannedCars);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetScannedCarsList),
    fork(watchGetFilteredScannedCars),
    fork(watchGetScannedCarItem),
    fork(watchGetAllScannedCars),
  ]);
}
