import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  apiUrl_matchedCars,
  internal_API,
  apiUrl_filter,
  driver_API,
  apiUrl_track,
  apiUrl_publicKey,
} from "../../constants/defaultValues";

import {
  MatchedCars_GET_LIST,
  MatchedCars_GET_LIST_WITH_FILTER,
  MatchedCar_GET_DETAILS,
  Track_GET,
  MatchedCars_ALL,
} from "../actions";

import {
  getMatchedCarsListSuccess,
  getMatchedCarsListError,
  getMatchedCarsListWithFilterSuccess,
  getMatchedCarsListWithFilterError,
  getMatchedCarDetailSuccess,
  getMatchedCarDetailError,
  getTrackInfoSuccess,
  getTrackInfoError,
  getMatchedCarsError,
  getMatchedCarsSuccess,
} from "./actions";
import axios from "axios";

const MatchedCarsListRequest = async ({ payload }) => {
  const { skip, pageSize } = payload || {};
  let url = internal_API + apiUrl_matchedCars;
  if (pageSize) {
    url = url + "?limit=" + pageSize + "&skip=" + skip;
  }
  return await axios
    .get(`${url}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getMatchedCarsList(payload) {
  try {
    const response = yield call(MatchedCarsListRequest, payload);
    if (response && (response.status === 200 || response.status === 201)) {
      response.data["currentPage"] = (payload.payload || {}).currentPage;
      yield put(getMatchedCarsListSuccess(response.data));
    } else {
      yield put(getMatchedCarsListError(response));
    }
  } catch (error) {
    yield put(getMatchedCarsListError(error));
  }
}

const MatchedCarsFilterRequest = async (payload) => {
  if (payload.column == "spy" || payload.column == "matched") {
    payload.value = payload.value === "true" ? true : false;
  }
  let options = JSON.stringify({
    val: payload.value,
    key: payload.column,
    type: "MatchedCars",
  });
  return await axios
    .get(`${internal_API + apiUrl_filter}?search=${options}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};
function* getFilteredMatchedCars({ payload }) {
  try {
    const response = yield call(MatchedCarsFilterRequest, payload);
    if (response && response.status === 200) {
      yield put(getMatchedCarsListWithFilterSuccess(response.data));
    } else {
      yield put(getMatchedCarsListWithFilterError(response));
    }
  } catch (error) {
    yield put(getMatchedCarsListWithFilterError("Not Found"));
  }
}

const getMatchedCarRequest = async (item) => {
  let itemId = item.payload;
  return await axios
    .get(`${internal_API + apiUrl_matchedCars}/${itemId}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then((response) => response)
    .catch((error) => error);
};

function* getMatchedCarItem(payload) {
  try {
    const response = yield call(getMatchedCarRequest, payload);
    if (response && (response.status === 200 || response.status == 201)) {
      yield put(getMatchedCarDetailSuccess(response.data));
    } else {
      yield put(getMatchedCarDetailError(response));
    }
  } catch (error) {
    yield put(getMatchedCarDetailError(error));
  }
}
const getTrackInfoRequest = async (item) => {
  let itemId = item.payload;
  return await axios
    .get(`${internal_API + apiUrl_publicKey}`, {
      headers: {
        "x-auth-token": localStorage.getItem("jwtToken"),
      },
    })
    .then(async (response) => {
      return await axios
        .get(`${driver_API + apiUrl_track + "/" + itemId}`, {
          headers: {
            authorization: response.data,
          },
        })
        .then((result) => {
          return result;
        })
        .catch((error) => error);
    })
    .catch((error) => error);
};

function* getTrackInfo(payload) {
  try {
    const response = yield call(getTrackInfoRequest, payload);
    if (response && (response.status === 200 || response.status == 201)) {
      yield put(getTrackInfoSuccess(response.data));
    } else {
      yield put(getTrackInfoError(response));
    }
  } catch (error) {
    yield put(getTrackInfoError(error));
  }
}
function* getMatchedCars() {
  try {
    const response = yield call(MatchedCarsListRequest, {});
    if (response && (response.status === 200 || response.status === 201)) {
      yield put(getMatchedCarsSuccess(response.data));
    } else {
      yield put(getMatchedCarsError(response));
    }
  } catch (error) {
    yield put(getMatchedCarsError(error));
  }
}
export function* watchGetMatchedCarsList() {
  yield takeEvery(MatchedCars_GET_LIST, getMatchedCarsList);
}
export function* watchGetFilteredMatchedCars() {
  yield takeEvery(MatchedCars_GET_LIST_WITH_FILTER, getFilteredMatchedCars);
}
export function* watchGetMatchedCarItem() {
  yield takeEvery(MatchedCar_GET_DETAILS, getMatchedCarItem);
}
export function* watchGetTrackInfo() {
  yield takeEvery(Track_GET, getTrackInfo);
}
export function* watchGetMatchedCars() {
  yield takeEvery(MatchedCars_ALL, getMatchedCars);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetMatchedCarsList),
    fork(watchGetFilteredMatchedCars),
    fork(watchGetMatchedCarItem),
    fork(watchGetMatchedCarItem),
    fork(watchGetTrackInfo),
    fork(watchGetMatchedCars),
  ]);
}
