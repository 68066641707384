import {
  Configurations_GET_LIST,
  Configurations_GET_LIST_SUCCESS,
  Configurations_GET_LIST_ERROR,
  Configurations_UPDATE,
  Configurations_UPDATE_SUCCESS,
  Configurations_UPDATE_ERROR,
  Config_Driver,
  Config_Driver_SUCCESS,
  Config_Driver_ERROR,
  Config_Driver_UPDATE,
  Config_Driver_UPDATE_SUCCESS,
  Config_Driver_UPDATE_ERROR,
} from "../actions";

const INIT_STATE = {
  configurationsList: null,
  error: "",
  filter: null,
  searchKeyword: "",
  orderColumn: null,
  loading: false,
  categories: [],
  selectedConfigurations: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Configurations_GET_LIST:
      return { ...state, loading: false };

    case Configurations_GET_LIST_SUCCESS:
      return { ...state, loading: true, configurationsList: action.payload };

    case Configurations_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Configurations_UPDATE:
      return { ...state, loading: false };

    case Configurations_UPDATE_SUCCESS:
      return { ...state, loading: true, configurationsList: action.payload };

    case Configurations_UPDATE_ERROR:
      return { ...state, loading: true, error: action.payload };

    case Config_Driver:
      return { ...state, isLoading: false };
    case Config_Driver_SUCCESS:
      return { ...state, isLoading: true, driverConfig: action.payload };
    case Config_Driver_ERROR:
      return { ...state, isLoading: true, error: action.payload };
    case Config_Driver_UPDATE:
      return { ...state, loading: false };
    case Config_Driver_UPDATE_SUCCESS:
      return { ...state, loading: true, driverConfig: [action.payload] };
    case Config_Driver_UPDATE_ERROR:
      return { ...state, loading: true, error: action.payload };

    default:
      return { ...state };
  }
};
