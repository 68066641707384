import {
  Configurations_GET_LIST,
  Configurations_GET_LIST_SUCCESS,
  Configurations_GET_LIST_ERROR,
  Configurations_UPDATE_SUCCESS,
  Configurations_UPDATE_ERROR,
  Configurations_UPDATE,
  Config_Driver,
  Config_Driver_SUCCESS,
  Config_Driver_ERROR,
  Config_Driver_UPDATE,
  Config_Driver_UPDATE_SUCCESS,
  Config_Driver_UPDATE_ERROR,
} from "../actions.js";

export const getConfigurations = () => ({
  type: Configurations_GET_LIST,
});

export const getConfigurationsListSuccess = (items) => ({
  type: Configurations_GET_LIST_SUCCESS,
  payload: items,
});

export const getConfigurationsListError = (error) => ({
  type: Configurations_GET_LIST_ERROR,
  payload: error,
});

export const updateConfigurations = (item) => ({
  type: Configurations_UPDATE,
  payload: item,
});

export const updateConfigurationsSuccess = (item) => ({
  type: Configurations_UPDATE_SUCCESS,
  payload: item,
});

export const updateConfigurationsError = (item) => ({
  type: Configurations_UPDATE_ERROR,
  payload: item,
});
export const getDriverConfig = () => ({
  type: Config_Driver,
});

export const getDriverConfigSuccess = (items) => ({
  type: Config_Driver_SUCCESS,
  payload: items,
});

export const getDriverConfigError = (error) => ({
  type: Config_Driver_ERROR,
  payload: error,
});
export const updateDriverConfig = (item) => ({
  type: Config_Driver_UPDATE,
  payload: item,
});

export const updateDriverConfigSuccess = (item) => ({
  type: Config_Driver_UPDATE_SUCCESS,
  payload: item,
});

export const updateDriverConfigError = (item) => ({
  type: Config_Driver_UPDATE_ERROR,
  payload: item,
});
