import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiUrl_store, internal_API} from "../../constants/defaultValues";

import {
  STORE_LIST_GET_LIST,
  STORE_LIST_ADD_ITEM
} from "../actions";

import {
  getStoreListSuccess,
  getStoreListError,
  addStoreItemSuccess,
  addStoreItemError
} from "./actions";
import axios from 'axios';


const getStoreListRequest = async () => {
  return await axios.get(`${internal_API+apiUrl_store}?limit=${100}&pageNo=${1}`,{
        headers: {
          "x-auth-token": localStorage.getItem('jwtToken')
        }
    })
    .then(response => response)
    .catch(error => error);
};

function* getStoreListItems() {
  try {
    const response = yield call(getStoreListRequest);
    if(response && response.status === 200){
      yield put(getStoreListSuccess(response.data));
    }else{
      yield put(getStoreListError(response));
    }
  } catch (error) {
    yield put(getStoreListError(error));
  }
}

const addStoreItemRequest = async item => {
  const options = {
    headers: {
      "x-auth-token": localStorage.getItem('jwtToken')
      }
  };
  var body = {
    "title": item.productName,
    "description": item.productDesc,
    "price": item.productPrice,
    "thumbnail": item.thumbnail,
    "dynamicPrice":item.productDynamicPrice
  };
  return await axios.post(`${internal_API+apiUrl_store}`,body,options)
.then(async (response) => {
  if(response && response.status === 200){
    return response; 
  }else{
    return response
  }
})
.catch(error => error);
};

function* addStoreItem({ payload }) {
  try {
    const response = yield call(addStoreItemRequest, payload);

    if(response && response.status === 200){
      yield put(addStoreItemSuccess(response.data));
    }else{
      yield put(addStoreItemError(response));
    }
  } catch (error) {
    yield put(addStoreItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(STORE_LIST_GET_LIST, getStoreListItems);
}

export function* wathcAddItem() {
  yield takeEvery(STORE_LIST_ADD_ITEM, addStoreItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem)]);
}
